import { combineReducers } from 'redux';

import * as accounts from './resources/accounts';
import * as accountTeamMembers from './resources/accountTeamMembers';
import * as articles from './resources/articles';
import * as authenticatorDevices from './resources/authenticatorDevices';
import * as cashFlowEvents from './resources/cashFlowEvents';
import * as clients from './resources/clients';
import * as costReports from './resources/costReports';
import * as configuration from './resources/configuration';
import * as documents from './resources/documents';
import * as events from './resources/events';
import * as expertInput from './resources/expertInput';
import * as family from './resources/family';
import * as feed from './resources/feed';
import * as feedCategories from './resources/feedCategories';
import * as filterValues from './resources/filterValues';
import * as financialValues from './resources/financialValues';
import * as forms from './resources/forms';
import * as funds from './resources/funds';
import * as goals from './resources/goals';
import * as illiquidCashflow from './resources/illiquidCashflow';
import * as illiquidSecurities from './resources/illiquidSecurities';
import * as investmentPreferences from './resources/investmentPreferences';
import * as investmentProfile from './resources/investmentProfile';
import * as investmentStrategies from './resources/investmentStrategies';
import * as investmentStrategyAssetAllocations from './resources/investmentStrategyAssetAllocations';
import * as investmentStrategyImplementationPlans from './resources/investmentStrategyImplementationPlans';
import * as investmentStrategyIndexChanges from './resources/investmentStrategyIndexChanges';
import * as investmentStrategyPortfolioCharacteristics from './resources/investmentStrategyPortfolioCharacteristics';
import * as investmentStrategyProducts from './resources/investmentStrategyProducts';
import * as investmentStrategyPreferences from './resources/investmentStrategyPreferences';
import * as investmentStrategyCosts from './resources/investmentStrategyCosts';
import * as investmentStrategySimulations from './resources/investmentStrategySimulations';
import * as marketValues from './resources/marketValues';
import * as meetings from './resources/meetings';
import * as milestones from './resources/milestones';
import * as network from './resources/network';
import * as offices from './resources/offices';
import * as operationalMessages from './resources/operationalMessages';
import * as organization from './resources/organization';
import * as personalGreeting from './resources/personalGreeting';
import * as podcastEpisodes from './resources/podcastEpisodes';
import * as portfolioIndices from './resources/portfolioIndices';
import * as periodicIndices from './resources/periodicIndices';
import * as portfolioLenses from './resources/portfolioLenses';
import * as portfolios from './resources/portfolios';
import * as securities from './resources/securities';
import * as securityPerformance from './resources/securityPerformance';
import * as signingEvents from './resources/signingEvents';
import * as standardFilters from './resources/standardFilters';
import * as strategy from './resources/strategy';
import * as tasks from './resources/tasks';
import * as taxDetails from './resources/taxDetails';
import * as tradeOrders from './resources/tradeOrders';
import * as transactions from './resources/transactions';
import * as users from './resources/users';
import * as wealthBalance from './resources/wealthBalance';
import * as webinars from './resources/webinars';
import * as assetClassBenchmarkIndices from './resources/assetClassBenchmarkIndices';

export const reducer = combineReducers({
  accounts: accounts.reducer,
  accountTeamMembers: accountTeamMembers.reducer,
  articles: articles.reducer,
  authenticatorDevices: authenticatorDevices.reducer,
  cashFlowEvents: cashFlowEvents.reducer,
  clients: clients.reducer,
  costReports: costReports.reducer,
  configuration: configuration.reducer,
  documents: documents.reducer,
  events: events.reducer,
  expertInputs: expertInput.reducer,
  family: family.reducer,
  feed: feed.reducer,
  feedCategories: feedCategories.reducer,
  filterValues: filterValues.reducer,
  financialValues: financialValues.reducer,
  forms: forms.reducer,
  funds: funds.reducer,
  goals: goals.reducer,
  illiquidCashflow: illiquidCashflow.reducer,
  illiquidSecurities: illiquidSecurities.reducer,
  investmentPreferences: investmentPreferences.reducer,
  investmentProfile: investmentProfile.reducer,
  investmentStrategies: investmentStrategies.reducer,
  investmentStrategyAssetAllocations:
    investmentStrategyAssetAllocations.reducer,
  investmentStrategyImplementationPlans:
    investmentStrategyImplementationPlans.reducer,
  investmentStrategyIndexChanges: investmentStrategyIndexChanges.reducer,
  investmentStrategyPortfolioCharacteristics:
    investmentStrategyPortfolioCharacteristics.reducer,
  investmentStrategyProducts: investmentStrategyProducts.reducer,
  investmentStrategyPreferences: investmentStrategyPreferences.reducer,
  investmentStrategyCosts: investmentStrategyCosts.reducer,
  investmentStrategySimulations: investmentStrategySimulations.reducer,
  marketValues: marketValues.reducer,
  meetings: meetings.reducer,
  milestones: milestones.reducer,
  network: network.reducer,
  offices: offices.reducer,
  operationalMessages: operationalMessages.reducer,
  organization: organization.reducer,
  personalGreeting: personalGreeting.reducer,
  podcastEpisodes: podcastEpisodes.reducer,
  portfolioIndices: portfolioIndices.reducer,
  periodicIndices: periodicIndices.reducer,
  portfolioLenses: portfolioLenses.reducer,
  portfolios: portfolios.reducer,
  securities: securities.reducer,
  securityPerformance: securityPerformance.reducer,
  signingEvents: signingEvents.reducer,
  standardFilters: standardFilters.reducer,
  strategy: strategy.reducer,
  tasks: tasks.reducer,
  taxDetails: taxDetails.reducer,
  tradeOrders: tradeOrders.reducer,
  transactions: transactions.reducer,
  wealthBalance: wealthBalance.reducer,
  webinars: webinars.reducer,
  users: users.reducer,
  assetClassBenchmarkIndices: assetClassBenchmarkIndices.reducer,
});
