import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';

import { ANNUAL_REVIEW_CONTENT_SPACING } from '../../../constants/spacing';
import { H1 } from '../../texts';
import { accent } from '../../../constants/colors';
import { PortfolioConstructionPyramidStaticLabel } from '../../portfolio/strategy/PortfolioConstructionPyramidStaticLabel';
import { PortfolioPyramid } from '../../common/PortfolioPyramid';
import {
  easeInOut,
  longAnimationTime,
  slideAnimationDelay,
} from '../../../constants/animation';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 130px;
  padding-left: ${ANNUAL_REVIEW_CONTENT_SPACING};
  padding-right: ${ANNUAL_REVIEW_CONTENT_SPACING};
`;

const Title = styled(H1)`
  color: ${accent.ocean4};
  max-width: 640px;
  transition: opacity ${easeInOut} ${longAnimationTime};
  transition-delay: ${slideAnimationDelay}s;
  opacity: 0;

  u {
    background: linear-gradient(
        rgba(199, 111, 242, 0.3),
        rgba(199, 111, 242, 0.3)
      )
      left bottom no-repeat;
    background-size: 100% 12px;
    position: relative;
    text-decoration: none;
    background-position-x: 0px;
    background-position-y: 45px;
  }

  ${(props) =>
    props.isActiveSlide &&
    css`
      opacity: 1;
    `}
`;

const PortfolioConstructionPyramidWrapper = styled.div`
  align-self: flex-end;
  position: relative;
  bottom: 50px;
  right: 50px;
`;

export const ConstructionSlide = (props) => {
  const { isActiveSlide } = props;
  const height = 460;
  // We use 364 as the base size for the pyramid scaling everything according to that
  const scaler = 364 / height;

  // TODO productStrategy: We need to make the strategy groups dynamic based on the strategy
  // as the values we are using now are directly tied to getAssetClassesByAssetClassGroup which we
  // want to keep as is with the old assetClass keys in case of redundancy
  const pyramidLabels = [
    {
      labelKey: 'AV',
      translationKey: 'alternativeGrowth',
      offset: -(80 / scaler),
      color: '#C76FF2',
    },
    {
      labelKey: 'MV',
      translationKey: 'marketGrowth',
      offset: -(10 / scaler),
      color: '#C5A5D6',
    },
    {
      labelKey: 'S',
      translationKey: 'stability',
      offset: 50 / scaler,
      color: '#6D4694',
    },
  ];

  return (
    <Base>
      <Title isActiveSlide={isActiveSlide}>
        <Trans
          i18nKey="presentation:investmentStrategy:construction:title"
          components={{
            strong: <u />,
          }}
        />
      </Title>

      <PortfolioConstructionPyramidWrapper>
        <PortfolioPyramid
          height={height}
          labels={pyramidLabels}
          enableClick={false}
          labelComponent={(item) => (
            <PortfolioConstructionPyramidStaticLabel labelKey={item.labelKey} />
          )}
          isActiveSlide={isActiveSlide}
        />
      </PortfolioConstructionPyramidWrapper>
    </Base>
  );
};
