import React from 'react';
import {
  getAssetClassGroupByAssetClass,
  getAssetClassName,
  getColorForAssetClass,
  percentage,
  sortByAssetClass,
} from '@formue-app/core';

import styled, { css } from 'styled-components';
import { H4, Paragraph } from '../../texts';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import {
  accent,
  assetClassDefaultColorScheme,
} from '../../../constants/colors';
import {
  easeInOut,
  fadeInFromBottom,
  mediumAnimationTime,
} from '../../../constants/animation';
import { SPACING_8 } from '../../../constants/spacing';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 400px;
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin-top: 7px;
  margin-bottom: 6px;
  background-color: ${accent.sand2};

  &:last-child {
    display: none;
  }

  opacity: 0;
  transform: translateY(60px);

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeInFromBottom} ${mediumAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.delay}s;
    `}
`;

const Item = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  margin: 6px 0;
  padding: 6px 0;

  opacity: 0;
  transform: translateY(60px);

  ${(props) =>
    props.animate &&
    css`
      animation: ${fadeInFromBottom} ${mediumAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.delay}s;
    `}
`;

const Icon = styled.div`
  width: 10px;
  height: 10px;
  border-radius: ${SPACING_8};
  margin-right: 12px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled(H4)``;

const Value = styled(Paragraph)`
  font-weight: 500;
`;

export const AssetClassesList = (props) => {
  const { assetAllocations, animate, index, ...rest } = props;

  if (!assetAllocations) return null;

  const groupedAssetAllocations = Object.values(
    assetAllocations
      .sort(sortByAssetClass)
      .reverse()
      .reduce((acc, item) => {
        const assetClassGroup = getAssetClassGroupByAssetClass(item.assetClass);
        if (!acc[assetClassGroup]) {
          acc[assetClassGroup] = [];
        }
        acc[assetClassGroup].push(item);
        return acc;
      }, {})
  );

  return (
    <Container {...rest}>
      {groupedAssetAllocations.map((group) => (
        <>
          {group.map((item, index) => (
            <Item
              key={`asset-class-item-${index}`}
              animate={animate}
              delay={1.3 + index * 0.1}
            >
              <IconWrapper>
                <Icon
                  style={{
                    backgroundColor: getColorForAssetClass(
                      item.assetClass,
                      assetClassDefaultColorScheme
                    ),
                  }}
                />
                <Title>{getAssetClassName(item.assetClass)}</Title>
              </IconWrapper>
              <Value>{percentage(item.strategyShare * 100)}</Value>
            </Item>
          ))}
          <StyledHorizontalDivider animate={animate} delay={1.3} />
        </>
      ))}
    </Container>
  );
};
