let globalConfiguration = {};

// Function to initialize the global configuration
export const initializeGlobalConfiguration = (config) => {
  globalConfiguration = { ...config };
};

// Function to get a specific configuration value
export const getConfigurationVariable = (key) => {
  return globalConfiguration[key];
};

// Optional: Function to update the configuration (e.g., for dynamic updates)
export const updateGlobalConfiguration = (obj) => {
  globalConfiguration = {
    ...globalConfiguration,
    ...obj,
  };
};

// Optional: Clear the configuration (e.g., for testing)
export const clearGlobalConfiguration = () => {
  globalConfiguration = {};
};
