import { LANGUAGE_TO_LANGUAGE_HEADER, t } from '../localization';
import { percentage } from '../localization';
import { getLocalizedMonthName, toLocaleDateString } from './dates';
import { getPredefinedRanges } from './filters';
import { getConfigurationVariable } from '../GlobalConfiguration';

export const getAssetGroupKey = (assetClass) => {
  let getAssetGroupKey;

  switch (assetClass.toLowerCase()) {
    case 'av':
      getAssetGroupKey = 'alternativeGrowth';
      break;
    case 'mv':
      getAssetGroupKey = 'marketGrowth';
      break;
    case 's':
      getAssetGroupKey = 'stability';
      break;
    default:
      return null;
  }

  return getAssetGroupKey;
};

export const getAssetClassName = (assetClass, short) => {
  const useNewAssetClassStructure = getConfigurationVariable(
    'useNewAssetClassStructure'
  );

  let assetClassName;

  switch (assetClass?.toLowerCase()) {
    case 'av':
      assetClassName = short
        ? t('assetClasses:short:alternativeGrowth')
        : t('assetClasses:alternativeGrowth');
      break;
    case 'mv':
      assetClassName = t('assetClasses:marketGrowth');
      break;
    case 's':
      assetClassName = t('assetClasses:stability');
      break;
    case 'b':
      assetClassName = t('assetClasses:moneyMarket');
      break;
    case 'g':
      assetClassName = t('assetClasses:hedgeFund');
      break;
    case 'i':
      assetClassName = t('assetClasses:property');
      break;
    case 'p':
      assetClassName = t('assetClasses:privateEquity');
      break;
    case 'o':
      // @deprecated - After migration this is not in use
      assetClassName = t('assetClasses:domesticBonds');
      break;
    case 'd':
      // @deprecated - After migration this is not in use
      assetClassName = t('assetClasses:domesticShares');
      break;
    case 'e':
      // @deprecated - After migration we use 'equities'
      assetClassName = t('assetClasses:foreignShares');
      break;
    case 't':
      // @deprecated - After migration we use 'bonds'
      assetClassName = t('assetClasses:foreignBonds');
      break;
    default:
      return null;
  }

  if (useNewAssetClassStructure) {
    switch (assetClass.toLowerCase()) {
      case 'e':
        assetClassName = t('assetClasses:equities');
        break;
      case 't':
        assetClassName = t('assetClasses:bonds');
        break;
    }
  }
  return assetClassName;
};

export const isPartOfTheAssetClassGroup = (assetGroup, assetClass) => {
  if (assetGroup === 'MV') {
    // @deprecated - assetClass 'd' is deprecated and will be removed in the future
    if (assetClass === 'd' || assetClass === 'e') return true;
  }
  if (assetGroup === 'AV') {
    if (assetClass === 'p' || assetClass === 'g' || assetClass === 'i')
      return true;
  }
  if (assetGroup === 'S') {
    // @deprecated - assetClass 'o' is deprecated and will be removed in the future
    if (assetClass === 'o' || assetClass === 'b' || assetClass === 't')
      return true;
  }
  return false;
};

export const formatGainValue = (value, renderSuffix = true) => {
  const prefix = value > 0 ? '+' : value < 0 ? '-' : '';
  return ` ${prefix} ${Math.round(Math.abs(value))
    .toString()
    .replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} ${renderSuffix ? 'kr' : ''}`;
};

export const formatTwrValue = (
  value,
  numberOfDecimals = 1,
  fixedNumberOfDecimals
) => {
  const prefix = value > 0 ? '+' : value < 0 ? '-' : '';
  return `${prefix}${percentage(
    Math.abs(value) * 100,
    numberOfDecimals,
    fixedNumberOfDecimals
  )}`;
};

export const formatTwrTicks = (
  value,
  numberOfDecimals = 1,
  alwaysUsePrefix
) => {
  let prefix = value < 0 ? '-' : '';
  prefix = alwaysUsePrefix ? (value < 0 ? '-' : value > 0 ? '+' : '') : prefix;
  return `${prefix}${percentage(Math.abs(value) * 100, numberOfDecimals)}`;
};

export const getColorForAssetClass = (assetClass, colorScheme) => {
  switch (assetClass) {
    case 'b':
      return colorScheme[0];
    case 'o':
      // @deprecated - assetClass 'o' is deprecated and will be removed in the future
      return colorScheme[0];
    case 't':
      return colorScheme[0];
    case 'd':
      // @deprecated - assetClass 'o' is deprecated and will be removed in the future
      return colorScheme[1];
    case 'e':
      return colorScheme[1];
    case 'g':
      return colorScheme[2];
    case 'i':
      return colorScheme[2];
    case 'p':
      return colorScheme[2];
    default:
      return null;
  }
};

export const getColorForAssetClassGroup = (assetClass, colorScheme) => {
  switch (assetClass) {
    case 'MV':
      return colorScheme[1];
    case 'AV':
      return colorScheme[2];
    case 'S':
      return colorScheme[0];
    default:
      return null;
  }
};

export const getAssetClassGroupByAssetClass = (assetClass) => {
  switch (assetClass) {
    case 'b':
    // @deprecated - assetClass 'o' is deprecated and will be removed in the future
    case 'o':
    case 't':
      return 'S';
    // @deprecated - assetClass 'o' is deprecated and will be removed in the future
    case 'd':
    case 'e':
      return 'MV';
    case 'g':
    case 'i':
    case 'p':
      return 'AV';
    default:
      return null;
  }
};

export const getAssetClassByAssetClassGroup = (assetClassGroup) => {
  const useNewAssetClassStructure = getConfigurationVariable(
    'useNewAssetClassStructure'
  );

  switch (assetClassGroup) {
    case 'S':
      return useNewAssetClassStructure ? ['b', 't'] : ['b', 'o', 't'];
    case 'MV':
      return useNewAssetClassStructure ? ['e'] : ['d', 'e'];
    case 'AV':
      return ['g', 'i', 'p'];
    default:
      return [];
  }
};

export const getAssetClassesByAssetClassGroup = (assetGroup) => {
  const useNewAssetClassStructure = getConfigurationVariable(
    'useNewAssetClassStructure'
  );

  switch (assetGroup) {
    case 'S': {
      return useNewAssetClassStructure ? ['t', 'b'] : ['o', 't', 'b'];
    }
    case 'MV':
      return useNewAssetClassStructure ? ['e'] : ['e', 'd'];
    case 'AV':
      return ['p', 'i', 'g'];
    default:
      return null;
  }
};

export const formatDateRangeLabel = (startDate, endDate, long) => {
  const start = new Date(startDate);
  const startMonth = getLocalizedMonthName(start.getMonth(), long, true);
  const startYear = start.getFullYear();

  let displayText = `${startMonth} ${startYear}`;

  if (endDate) {
    const end = new Date(endDate);
    const endMonth = getLocalizedMonthName(end.getMonth(), long, true);
    const endYear = end.getFullYear();

    if (startMonth !== endMonth || startYear !== endYear) {
      displayText += ` - ${endMonth} ${endYear}`;
    }
  }

  return displayText;
};

export const formatPeriodLabel = (
  period,
  startDate,
  endDate,
  exact = false,
  long = false
) => {
  if (exact) {
    const { newStartDate, newEndDate } = getPredefinedRanges(period);

    // In economic terms dates always represents the end of day
    // 01.04.24 would not include the gains done on the 1st of April, hence the correct date
    // should be 31.03.24
    const start = new Date(newStartDate || startDate);
    start.setDate(start.getDate() - 1);

    return `${toLocaleDateString(start)} - ${toLocaleDateString(
      newEndDate || endDate
    )}`;
  }

  if (period === 'CUSTOM') {
    return formatDateRangeLabel(startDate, endDate, long);
  } else if (period === 'THISMONTH') {
    return getLocalizedMonthName(new Date().getMonth());
  } else {
    return t(`filters:period:${period}`);
  }
};

export const truncateString = (string, number) => {
  if (!string) {
    return null;
  }

  if (string.length <= number) {
    return string;
  }
  return string.slice(0, number) + '...';
};

export const sumAssetClassesByAssetGroup = (assetClasses) => {
  return Object.values(
    assetClasses.reduce((accumulator, current) => {
      const assetGroup = getAssetClassGroupByAssetClass(current.assetClass);
      if (!accumulator[assetGroup]) {
        accumulator[assetGroup] = { id: assetGroup, value: 0 };
      }
      accumulator[assetGroup].value += current.strategyShare;
      return accumulator;
    }, {})
  );
};

export const getAssetAllocationGroups = (assetAllocations) =>
  assetAllocations.reduce((accumulator, current) => {
    const assetClassGroup = getAssetClassGroupByAssetClass(current.assetClass);
    if (accumulator.hasOwnProperty(assetClassGroup)) {
      return {
        ...accumulator,
        [assetClassGroup]: {
          strategyShare:
            accumulator[assetClassGroup].strategyShare + current.strategyShare,
          formueStrategyShare:
            accumulator[assetClassGroup].formueStrategyShare +
            current.formueStrategyShare,
        },
      };
    }
    return {
      ...accumulator,
      [assetClassGroup]: {
        ...current,
      },
    };
  }, {});

export const getCSVDownloadLink = (
  urlBase,
  activeFilters,
  chosenLanguage,
  accessToken
) => {
  const ssids = activeFilters.ssids.join(',');
  const taxClasses = [
    ...new Set(activeFilters.taxClass.map((x) => encodeURIComponent(x))),
  ].join(',');
  const accounts = [
    ...new Set(activeFilters.account.map((x) => encodeURIComponent(x))),
  ].join(',');
  const period = activeFilters.period;
  const internal = activeFilters.showInternalPortfolio;
  const external = activeFilters.showExternalPortfolio;
  const language = LANGUAGE_TO_LANGUAGE_HEADER[chosenLanguage];

  let link = urlBase;

  link +=
    `?secret_token=${accessToken}&ssids=${ssids}` +
    `&showInternalPortfolio=${internal}&` +
    `showExternalPortfolio=${external}&taxclass=${taxClasses}` +
    `&account=${accounts}&language=${language}`;

  if (period === 'CUSTOM') {
    const startDate = activeFilters.startDate;
    const _endDate = new Date(activeFilters.endDate);
    const endDate = new Date(
      _endDate.getFullYear(),
      _endDate.getMonth() + 1,
      2 // Add two days to account for timezone issues
    )
      .toISOString()
      .split('T')[0];

    link += `&startDate=${startDate}&endDate=${endDate}`;
  } else {
    link += `&period=${period}`;
  }

  return link;
};
