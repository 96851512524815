import { createSelector } from 'reselect';
import { resourceSelectors } from '../../utils/resourceSelectors';

export const [
  investmentStrategyPreferencesAllIds,
  investmentStrategyPreferencesById,
  investmentStrategyPreferencesSelector,
  investmentStrategyPreferencesMetadataSelector,
] = resourceSelectors('investmentStrategyPreferences');

export const investmentStrategyPreferencesByStrategyIdSelector = (strategyId) =>
  createSelector(investmentStrategyPreferencesSelector, (allCosts) =>
    allCosts.filter((product) => product.investmentStrategy.id == strategyId)
  );

export const investmentStrategyCostMetadataByIdSelector = (id) =>
  createSelector(
    investmentStrategyPreferencesMetadataSelector,
    (meta) => meta[id]
  );
