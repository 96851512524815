import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import {
  getAssetClassGroupByAssetClass,
  getAssetClassName,
  percentage,
  selectors,
  useResource,
} from '@formue-app/core';

import {
  ANNUAL_REVIEW_CONTENT_SPACING,
  BORDER_RADIUS_LARGE,
  SPACING_16,
} from '../../../constants/spacing';
import { accent } from '../../../constants/colors';
import { AssetClassesList } from '../investmentStrategyComponents/AssetClassesList';
import { H1, H2Small, H3 } from '../../texts';
import { investmentStrategyAssetAllocationsByStrategyIdSelector } from '@formue-app/core/src/store/entities/selectors/investmentStrategyAssetAllocations';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { PortfolioPyramid } from '../../common/PortfolioPyramid';
import { easeInOut, shortAnimationTime } from '../../../constants/animation';

const Base = styled.div`
  display: flex;
  padding: ${ANNUAL_REVIEW_CONTENT_SPACING};
  flex: 1;
`;

const TitleWrapper = styled.div`
  flex-direction: column-reverse;
  gap: 8px;
  width: 100%;
  padding-top: 130px;
  padding-left: 95px;
  padding-right: 95px;
  z-index: 1;
`;

const PyramidWrapper = styled.div`
  position: absolute;
  left: -270px;
  top: 230px;
`;

const LeftSection = styled.div`
  width: 650px;
  height: 100%;
`;

const ProgressBar = styled.div`
  height: ${SPACING_16};
  width: 350px;
  background: ${accent.sand2};
  border-radius: ${BORDER_RADIUS_LARGE};
  overflow: hidden;
`;

const ProgressBarInner = styled.div`
  height: 100%;
  border-radius: ${BORDER_RADIUS_LARGE};
`;

const LabelWrapper = styled.div`
  position: relative;
  left: -70px;
  justify-content: flex-start;
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

const Label = styled(H3)`
  transition: all ${easeInOut} ${shortAnimationTime};
`;

const {
  entities: {
    strategy: { allStrategiesSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

export const StrategyAlternativeSlide = (props) => {
  const { isActiveSlide, strategyId } = props;
  const [, setActiveStrategy] = useState(null);

  const allStrategies = useSelector(allStrategiesSelector);
  const activeStrategyId = useSelector(activeStrategySelector);
  const investmentStrategiesById = useSelector(
    (state) => state.entities.investmentStrategies.byId
  );

  const strategy = investmentStrategiesById[strategyId];

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [allStrategies, activeStrategyId]);

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId },
    },
  ]);

  const assetAllocations = useSelector(
    investmentStrategyAssetAllocationsByStrategyIdSelector(strategyId)
  );

  const assetAllocationGroups = assetAllocations.reduce(
    (accumulator, current) => {
      const assetClassGroup = getAssetClassGroupByAssetClass(
        current.assetClass
      );
      if (accumulator.hasOwnProperty(assetClassGroup)) {
        return {
          ...accumulator,
          [assetClassGroup]: {
            strategyShare:
              accumulator[assetClassGroup].strategyShare +
              current.strategyShare,
          },
        };
      }
      return {
        ...accumulator,
        [assetClassGroup]: {
          ...current,
        },
      };
    },
    {}
  );

  const pyramidLabels = assetAllocations.length
    ? [
        {
          labelKey: 'AV',
          translationKey: 'alternativeGrowth',
          strategy: assetAllocationGroups?.AV?.strategyShare,
          color: '#C76FF2',
        },
        {
          labelKey: 'MV',
          translationKey: 'marketGrowth',
          strategy: assetAllocationGroups?.MV?.strategyShare,
          color: '#C5A5D6',
        },
        {
          labelKey: 'S',
          translationKey: 'stability',
          strategy: assetAllocationGroups?.S?.strategyShare,
          color: '#6D4694',
        },
      ]
    : [];

  return (
    <div>
      <TitleWrapper>
        <H1>{strategy.title}</H1>
      </TitleWrapper>

      <Base>
        {loading ? (
          <CenteredActivityIndicator />
        ) : (
          <>
            <LeftSection>
              <PyramidWrapper>
                <PortfolioPyramid
                  isActiveSlide={isActiveSlide}
                  style={{ marginTop: 'auto', marginBottom: 180 }}
                  height={550}
                  progressBar={true}
                  enableClick={false}
                  valueColor={accent.ocean490}
                  labelColor={accent.ocean490}
                  labels={pyramidLabels}
                  labelComponent={(item) => (
                    <LabelWrapper style={{ marginLeft: item.index * 70 }}>
                      <Label>{getAssetClassName(item.labelKey)}</Label>
                      <H2Small
                        style={{
                          color: accent.ocean490,
                          fontSize: '0.9em',
                          lineHeight: '0.9em',
                          padding: '8px 0',
                        }}
                      >
                        {percentage(item.strategy * 100, 1)}
                      </H2Small>

                      <ProgressBar>
                        <ProgressBarInner
                          style={{
                            width: `${item.strategy * 100}%`,
                            backgroundColor: item.color,
                          }}
                        />
                      </ProgressBar>
                    </LabelWrapper>
                  )}
                />
              </PyramidWrapper>
            </LeftSection>

            <AssetClassesList
              assetAllocations={assetAllocations}
              style={{ marginLeft: 160 }}
              animate={isActiveSlide}
            />
          </>
        )}
      </Base>
    </div>
  );
};
