import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { useErrorState, useResource, usePrevious, t } from '@formue-app/core';

import { shortAnimationTime } from '../../../constants/animation';
import { PresentationErrorMessage } from './PresentationErrorMessage';
import { PresentationConfirmationMessage } from './PresentationConfirmationMessage';
import { useQueryState } from 'react-router-use-location-state';
import { useLocation } from 'react-router-dom';

const MessagesWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 16px;
  z-index: 100;
  opacity: 0;
  z-index: 0;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      z-index: 10;
    `}

  transition: linear all ${shortAnimationTime};
`;

export const InvestmentStrategyExportPresentationMessages = (props) => {
  const { search } = useLocation();
  const strategyId = new URLSearchParams(search).get('strategyId');
  const [visible, setVisible] = useState(false);
  const [isClosed, setIsClosed] = useState(false);

  const [exportMode] = useQueryState('exportMode', false);

  const strategyError = useErrorState([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_INDEX_CHANGES/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_IMPLEMENTATION_PLANS/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId },
    },
  ]);

  const loading = useResource([
    'INVESTMENT_STRATEGIES/INDEX',
    {
      type: 'INVESTMENT_STRATEGY_INDEX_CHANGES/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_PRODUCT_ALLOCATIONS/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_IMPLEMENTATION_PLANS/INDEX',
      args: { strategyId },
    },
    {
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId },
    },
  ]);

  const isErrorMessage = strategyError || !strategyId; // If we dont have a strategyID its an automatic error
  const previousIsErrorMessage = usePrevious(isErrorMessage);

  // Show error message if there is now strategyID
  useEffect(() => {
    if (!strategyId) {
      setVisible(true);
    }
  }, [strategyId]);

  useEffect(() => {
    if (!loading) {
      if (previousIsErrorMessage) {
        setVisible(true);
      } else {
        if (isErrorMessage) {
          setVisible(true);
        }
        if (!isClosed) {
          setVisible(true);
        }
      }
    }
  }, [loading, previousIsErrorMessage, isErrorMessage, isClosed]);

  // Make sure strategyID is set before evaluating loading state
  if ((loading && strategyId) || exportMode) return null;

  return (
    <MessagesWrapper visible={visible}>
      {isErrorMessage ? (
        <PresentationErrorMessage
          resources={[
            { title: t('portfolio:strategy'), isError: strategyError },
            {
              title: 'Missing strategy ID in URL', // TODO: add trsnlation for this
              isError: !strategyId,
            },
          ]}
          closeCallback={() => {
            setVisible(false);
          }}
        />
      ) : (
        <PresentationConfirmationMessage
          closeCallback={() => {
            setVisible(false);
            setIsClosed(true);
          }}
        />
      )}
    </MessagesWrapper>
  );
};
