import * as accounts from './resources/accounts';
import * as accountTeamMembers from './resources/accountTeamMembers';
import * as articles from './resources/articles';
import * as authenticatorDevices from './resources/authenticatorDevices';
import * as cashFlowEvents from './resources/cashFlowEvents';
import * as clients from './resources/clients';
import * as costReports from './resources/costReports';
import * as configuration from './resources/configuration';
import * as documents from './resources/documents';
import * as events from './resources/events';
import * as expertInput from './resources/expertInput';
import * as family from './resources/family';
import * as feed from './resources/feed';
import * as feedCategories from './resources/feedCategories';
import * as filterValues from './resources/filterValues';
import * as financialValues from './resources/financialValues';
import * as forms from './resources/forms';
import * as funds from './resources/funds';
import * as goals from './resources/goals';
import * as illiquidCashflow from './resources/illiquidCashflow';
import * as illiquidSecurities from './resources/illiquidSecurities';
import * as investmentPreferences from './resources/investmentPreferences';
import * as investmentProfile from './resources/investmentProfile';
import * as investmentStrategies from './resources/investmentStrategies';
import * as investmentStrategyAssetAllocations from './resources/investmentStrategyAssetAllocations';
import * as investmentStrategyImplementationPlans from './resources/investmentStrategyImplementationPlans';
import * as investmentStrategyIndexChanges from './resources/investmentStrategyIndexChanges';
import * as investmentStrategyPortfolioCharacteristics from './resources/investmentStrategyPortfolioCharacteristics';
import * as investmentStrategyProducts from './resources/investmentStrategyProducts';
import * as investmentStrategyPreferences from './resources/investmentStrategyPreferences';
import * as investmentStrategyCosts from './resources/investmentStrategyCosts';
import * as investmentStrategySimulations from './resources/investmentStrategySimulations';
import * as marketValues from './resources/marketValues';
import * as meetings from './resources/meetings';
import * as milestones from './resources/milestones';
import * as network from './resources/network';
import * as offices from './resources/offices';
import * as operationalMessages from './resources/operationalMessages';
import * as organization from './resources/organization';
import * as personalGreeting from './resources/personalGreeting';
import * as podcastEpisodes from './resources/podcastEpisodes';
import * as portfolioIndices from './resources/portfolioIndices';
import * as periodicIndices from './resources/periodicIndices';
import * as portfolioLenses from './resources/portfolioLenses';
import * as portfolios from './resources/portfolios';
import * as securities from './resources/securities';
import * as securityPerformance from './resources/securityPerformance';
import * as signingEvents from './resources/signingEvents';
import * as standardFilters from './resources/standardFilters';
import * as strategy from './resources/strategy';
import * as tasks from './resources/tasks';
import * as taxDetails from './resources/taxDetails';
import * as tradeOrders from './resources/tradeOrders';
import * as transactions from './resources/transactions';
import * as users from './resources/users';
import * as wealthBalance from './resources/wealthBalance';
import * as webinars from './resources/webinars';
import * as assetClassBenchmarkIndices from './resources/assetClassBenchmarkIndices';

export const actions = {
  accounts: accounts.actions,
  accountTeamMembers: accountTeamMembers.actions,
  articles: articles.actions,
  authenticatorDevices: authenticatorDevices.actions,
  cashFlowEvents: cashFlowEvents.actions,
  clients: clients.actions,
  costReports: costReports.actions,
  configuration: configuration.actions,
  documents: documents.actions,
  events: events.actions,
  expertInput: expertInput.actions,
  family: family.actions,
  feed: feed.actions,
  feedCategories: feedCategories.actions,
  filterValues: filterValues.actions,
  financialValues: financialValues.actions,
  forms: forms.actions,
  funds: funds.actions,
  goals: goals.actions,
  illiquidCashflow: illiquidCashflow.actions,
  illiquidSecurities: illiquidSecurities.actions,
  investmentPreferences: investmentPreferences.actions,
  investmentProfile: investmentProfile.actions,
  investmentStrategies: investmentStrategies.actions,
  investmentStrategyAssetAllocations:
    investmentStrategyAssetAllocations.actions,
  investmentStrategyImplementationPlans:
    investmentStrategyImplementationPlans.actions,
  investmentStrategyIndexChanges: investmentStrategyIndexChanges.actions,
  investmentStrategyProducts: investmentStrategyProducts.actions,
  investmentStrategyCosts: investmentStrategyCosts.actions,
  investmentStrategyPreferences: investmentStrategyPreferences.actions,
  investmentStrategyPortfolioCharacteristics:
    investmentStrategyPortfolioCharacteristics.actions,
  investmentStrategySimulations: investmentStrategySimulations.actions,
  marketValues: marketValues.actions,
  meetings: meetings.actions,
  milestones: milestones.actions,
  network: network.actions,
  offices: offices.actions,
  operationalMessages: operationalMessages.actions,
  organization: organization.actions,
  personalGreeting: personalGreeting.actions,
  podcastEpisodes: podcastEpisodes.actions,
  portfolioIndices: portfolioIndices.actions,
  periodicIndices: periodicIndices.actions,
  portfolioLenses: portfolioLenses.actions,
  portfolios: portfolios.actions,
  securities: securities.actions,
  securityPerformance: securityPerformance.actions,
  signingEvents: signingEvents.actions,
  standardFilters: standardFilters.actions,
  strategy: strategy.actions,
  tasks: tasks.actions,
  taxDetails: taxDetails.actions,
  tradeOrders: tradeOrders.actions,
  transactions: transactions.actions,
  wealthBalance: wealthBalance.actions,
  webinars: webinars.actions,
  users: users.actions,
  assetClassBenchmarkIndices: assetClassBenchmarkIndices.actions,
};
