import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  getAssetClassGroupByAssetClass,
  getAssetClassName,
  getColorForAssetClassGroup,
  percentage,
  sortByAssetClass,
} from '@formue-app/core';

import { Button } from '../../buttons';
import { H3, H4, Paragraph, ParagraphSmall } from '../../texts';
import { Input } from '../../formElements';
import { HorizontalDivider } from '../../common/HorizontalDivider';

import {
  accent,
  assetClassDefaultColorScheme,
  uiNegative,
} from '../../../constants/colors';
import { paragraphSmallSize } from '../../../constants/text';
import { SPACING_24, SPACING_16, SPACING_8 } from '../../../constants/spacing';

// Need PNG since svg border offsets are a pain, easier to render out as raster
import BoundariesIcon from '../../../assets/icons/advisor-corner/boundaries-icon.png';

const StyledAssetClassList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  width: 100%;
  max-width: 400px;
  height: 100%;
`;

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin-top: 7px;
  margin-bottom: 6px;

  &:last-child {
    display: none;
  }
`;

const AssetClassListItem = styled.li`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
  padding-block: 7px;
  width: 100%;
`;

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const EditWrapper = styled.div`
  border-top: 1px solid ${accent.sand1};
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  padding-top: ${SPACING_16};
  margin-top: ${SPACING_16};
`;

const TextWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BounderiesWrapper = styled.span`
  display: flex;
  align-items: center;
  margin-left: auto;
  gap: ${SPACING_24};
`;

const InputWrapper = styled.span`
  position: relative;

  &:after {
    content: '%';
    position: absolute;
    top: 50%;
    right: 8px;
    translate: 0 -50%;
  }
`;

const StyledInput = styled(Input)`
  width: 70px;
  min-width: auto;
  border: 1px solid ${accent.ocean440};
  padding: 5px 8px;
  padding-right: 23px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ${(props) =>
    props.error &&
    css`
      border-color: ${accent.raspberry370};
      background-color: ${accent.raspberry330};
    `}
`;

const Bounderies = styled.div`
  display: flex;
  align-items: center;
  gap: ${SPACING_8};
  justify-content: space-evenly;
  min-width: 140px;
`;

const Percentage = styled(Paragraph)`
  margin-left: auto;
  font-weight: 500;
  min-width: 60px;
  text-align: right;
`;

// Need important to override print default
const BoundariesParagraph = styled(ParagraphSmall)`
  color: ${accent.ocean450} !important;
  font-weight: 500;
  font-size: ${paragraphSmallSize} !important;
  min-width: 43px;
`;

export const AssetClassList = (props) => {
  const {
    showInputs = true,
    editMode,
    setEditMode,
    editableAssetAllocations,
    setEditableAssetAllocations,
  } = props;

  // Allow passing what object keys to use when getting the share and limits so we can
  // re-use this list regardless of we are showing the "Total portfolio" or the "Formue portfolio"
  const lowerLimitKey = props.lowerLimitKey || 'lowerLimit';
  const upperLimitKey = props.upperLimitKey || 'upperLimit';
  const strategyShareKey = props.strategyShareKey || 'strategyShare';

  const [totalStrategy, setTotalStrategy] = useState(null);
  const [errors, setErrors] = useState(
    editableAssetAllocations
      ? {
          total: totalStrategy > 100,
          ...editableAssetAllocations
            .map((item) => item.assetClass)
            .reduce((a, key) => Object.assign(a, { [key]: false }), {}),
        }
      : {}
  );

  useEffect(() => {
    // Sum up all allocations strategyShare
    setTotalStrategy(
      editableAssetAllocations.reduce(
        (total, item) => total + item[strategyShareKey] || 0,
        0
      ) * 100
    );

    const currErrorState = {};
    currErrorState['total'] = totalStrategy > 100;

    editableAssetAllocations.forEach((item) => {
      const errorConditions =
        item[strategyShareKey] < 0 ||
        typeof item[strategyShareKey] === 'object' ||
        item[strategyShareKey] < item[lowerLimitKey] ||
        item[strategyShareKey] > item[upperLimitKey];

      currErrorState[item.assetClass] = errorConditions;
    });

    setErrors({
      ...errors,
      ...currErrorState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableAssetAllocations]);

  const onUpdateStrategy = (e, assetClass) => {
    // Set strategyShare only if for the assetClass that changed
    setEditableAssetAllocations(
      editableAssetAllocations.map((item) => ({
        ...item,
        strategyShare:
          item.assetClass === assetClass
            ? e.target.value
              ? parseInt(e.target.value) / 100
              : null
            : item.strategyShare,
      }))
    );
  };

  const groupedAssetAllocations = Object.values(
    editableAssetAllocations
      .sort(sortByAssetClass)
      .reverse()
      .reduce((acc, item) => {
        const assetClassGroup = getAssetClassGroupByAssetClass(item.assetClass);
        if (!acc[assetClassGroup]) {
          acc[assetClassGroup] = [];
        }
        acc[assetClassGroup].push(item);
        return acc;
      }, {})
  );

  return (
    <StyledAssetClassList>
      {groupedAssetAllocations.map((group) => (
        <>
          {group.map((item) => (
            <AssetClassListItem key={`assetclass-${item.assetClass}`}>
              <Dot
                style={{
                  backgroundColor: getColorForAssetClassGroup(
                    getAssetClassGroupByAssetClass(item.assetClass),
                    assetClassDefaultColorScheme
                  ),
                }}
              />
              <H4>{getAssetClassName(item.assetClass)}</H4>
              {!editMode ? (
                <Percentage>
                  {percentage(item[strategyShareKey] * 100)}
                </Percentage>
              ) : (
                <BounderiesWrapper>
                  <Bounderies>
                    <BoundariesParagraph style={{ textAlign: 'right' }}>
                      {percentage(item[lowerLimitKey] * 100)}
                    </BoundariesParagraph>
                    <img
                      src={BoundariesIcon}
                      alt="Boundaries"
                      style={{ width: 33, height: 15 }}
                    />
                    <BoundariesParagraph>
                      {percentage(item[upperLimitKey] * 100)}
                    </BoundariesParagraph>
                  </Bounderies>
                  {showInputs ? (
                    <InputWrapper>
                      <StyledInput
                        onChange={(e) => onUpdateStrategy(e, item.assetClass)}
                        type="number"
                        value={item[strategyShareKey] * 100}
                        error={errors[item.assetClass]}
                      />
                    </InputWrapper>
                  ) : (
                    <Percentage>
                      {percentage(item[strategyShareKey] * 100)}
                    </Percentage>
                  )}
                </BounderiesWrapper>
              )}
            </AssetClassListItem>
          ))}
          <StyledHorizontalDivider />
        </>
      ))}
      {editMode && showInputs ? (
        <EditWrapper>
          <TextWrapper>
            <H3>Total</H3>
            <H3 style={errors.total ? { color: uiNegative } : null}>
              {percentage(totalStrategy)}
            </H3>
          </TextWrapper>
          <Button
            disabled={Object.values(errors).some((item) => item === true)}
            onClick={() => setEditMode(false)}
          >
            Save
          </Button>
        </EditWrapper>
      ) : null}
    </StyledAssetClassList>
  );
};
