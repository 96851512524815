import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { GridElement } from '../../../../components/layout';
import { CollapsibleListItem } from '../../../../components/common/CollapsibleListItem';
import { H3, List, ListItem, Paragraph } from '../../../../components/texts';

import { Toggle } from '../../../../components/formElements';
import { desktopCondition, tabletCondition } from '../../../../constants/media';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { InfoCard } from '../../../../components/common/InfoCard';

const StyledInfoCard = styled(InfoCard)`
  margin-top: 10px;

  @media ${desktopCondition} {
    margin-right: 40px;
  }

  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  max-width: 75%;
  padding: ${SPACING_24};
  padding-top: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 55px;
  margin-bottom: ${SPACING_16};
`;

export const GeneralPage = (props) => {
  const [openAll, setOpenAll] = useState(false);

  const faqData = [
    {
      title: 'Investeringssparkonto (ISK)',
      detailsStyle: { padding: 0 },
      details: (
        <ContentWrapper>
          <Paragraph>
            För tillgångar som finns på ett investeringssparkonto ska den som
            kontoför investeringssparkontot fastställa ett kapitalunderlag och
            beräkna en schablonintäkt. Schablonintäkten beräknas genom att
            kapitalunderlaget multipliceras med 3,62 % för inkomståret 2024
            (statslåneräntan 30 november 2023 ökat med en procentenhet, minimum
            1,25 %). Schablonintäkten beskattas som inkomst av kapital med 30 %.
            Det innebär att skatten blir 1,086 % av värdet på dina tillgångar
            (kapitalunderlaget) eftersom 30 % av 3,62 % är 1,086 %.
          </Paragraph>
          <Paragraph>
            Kontoföraren av investeringssparkontot lämnar kontrolluppgift till
            Skatteverket på schablonintäkten som blir förtryckt i kommande
            inkomstdeklaration. Avdrag för preliminärskatt har inte gjorts.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Kapitalförsäkring',
      details: (
        <ContentWrapper>
          <Paragraph>
            För kapitalförsäkringar fastställs ett kapitalunderlag som för
            inkomståret 2024 multipliceras med 3,62 % för att få
            skatteunderlaget. Skatten, som betecknas som avkastningsskatt,
            beräknas med 30 % av skatteunderlaget. Svenska försäkringsbolag är
            ansvariga för att betala avkastningsskatten till Skatteverket vilket
            innebär att du inte själv behöver deklarera ditt innehav i en svensk
            kapitalförsäkring.
          </Paragraph>
          <Paragraph>
            För utländska kapitalförsäkringar blir i vissa fall inte
            skatteunderlaget förtryckt i deklarationen, du måste då själv ta upp
            skatteunderlaget i din inkomstdeklaration. Underlaget för skatten
            redovisar du vid punkt 9.1 i inkomstdeklarationen. Avdrag för
            preliminärskatt har inte gjorts.
          </Paragraph>
          <Paragraph>
            För bolag: För svenska kapitalförsäkringar är det försäkringsbolaget
            som betalar in avkastningsskatten till Skatteverket. Skatten, som
            betecknas som avkastningsskatt, beräknas med 30 % av
            skatteunderlaget. För utländska kapitalförsäkringar måste
            aktiebolaget ta upp skatteunderlaget i inkomstdeklarationen.
            Underlaget för skatten redovisas vid punkt 1.7b i
            inkomstdeklarationen. Avkastningsskatten på utländsk
            kapitalförsäkring är inte avdragsgill och ska återföras till
            beskattning i deklarationen.
          </Paragraph>
          <Paragraph>
            Notera att förvaltningsarvode hänförlig till kapitalförsäkring inte
            är avdragsgill och ska återföras till beskattning i deklarationen.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Fondandelar i värdepappersdepå ',
      details: (
        <ContentWrapper>
          <Paragraph>
            För fondandelar beräknas schablonintäkten genom att värdet av
            fondandelarna vid beskattningsårets början multipliceras med 0,4%.
            Schablonintäkten blir förtryckt i inkomstdeklarationen och skatten
            är 30 % av schablonintäkten. Avdrag för preliminärskatt har inte
            gjorts.
          </Paragraph>
          <Paragraph>
            För bolag: Om du äger värdepappersfonder i ditt aktiebolag (som ej
            ska tas upp till verkligt värde i beskattningen) ska du redovisa
            schablonintäkt. För fondandelar i en värdepappersdepå beräknas
            schablonintäkten genom att marknadsvärdet av fondandelarna vid
            kalenderårets början multipliceras med 0,4 %. Schablonintäkten
            deklareras som en ej redovisad intäkt i ruta 4.6.b.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Annan inkomst',
      details: (
        <ContentWrapper>
          <Paragraph>
            Annan inkomst avser erhållna returprovisioner i vanlig
            värdepappersdepå. Avdrag för preliminärskatt har inte gjorts.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Investeringar i onoterade bolag  ',
      details: (
        <ContentWrapper>
          <Paragraph>
            För utbetalningar till aktieägarna från investeringar i onoterade
            bolag via Formue skickar Formue enbart information om totalt
            utbetalat belopp till Skatteverket. Därför behöver du själv skicka
            en K12-blankett till Skatteverket med ytterligare information.
            Formue assisterar berörda privatkunder med att ta fram en ifylld
            K12-blankett (okvalificerade andelar). Blanketten kommer att finnas
            på kundportalen för berörda kunder under februari månad.
          </Paragraph>
          <Paragraph>
            Källskatt (utländsk skatt) har debiterats i Norge för utbetalningar
            som har genomförts till aktieägarna under år 2024 för dessa två
            norska onoterade bolag (okvalificerade andelar):
          </Paragraph>
          <List stlye={{ margin: 0 }}>
            <ListItem>FO Nordic Private Equity 2008 AS </ListItem>
            <ListItem>FO Private Equity 2011 AS </ListItem>
          </List>
          <Paragraph>
            Se även separat informationsdokument med ytterligare information om
            detta. Dokumentet kommer att finnas på kundportalen för berörda
            kunder under februari månad.
          </Paragraph>
          <Paragraph>
            För bolag: Innehav i ovan onoterade aktiebolag hanteras som
            näringsbetingade andelar under förutsättning att tillgångarna
            hanteras som kapitaltillgångar i bolaget. Utdelning och kapitalvinst
            på näringsbetingade andelar är ej skattepliktiga och
            kapitalförluster är ej avdragsgilla.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title:
        'Erhållen försäkringsprovision från Nordnet och övriga försäkringar',
      details: (
        <ContentWrapper>
          <Paragraph>
            Har du via oss erhållit försäkringsprovision från Nordnet och/eller
            övriga försäkringar som betalats ut till dig behöver du ta upp det i
            din inkomstdeklaration. Dessa uppgifter är inte lämnade till
            Skatteverket. Ett separat brev med information om erhållet belopp
            kommer att finnas på kundportalen för berörda kunder under februari
            månad.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Några datum att komma ihåg - för dig som privatperson. ',
      details: (
        <ContentWrapper>
          <List stlye={{ margin: 0 }}>
            <ListItem>
              I slutet av januari publicerar vi deklarationsunderlag i
              kundportalen och i Formue-appen.
            </ListItem>
            <ListItem>
              Den behållning som fanns i dina depåer / försäkringar vid
              årsskiftet 2023 / 2024 finner du i månadsrapporten för december
              2023.
            </ListItem>
            <ListItem>
              Senast den 12 februari:
              <br />
              Om du räknar med kvarskatt på mer än 30 000 kronor ska du ha gjort
              en eventuell extra skatteinbetalning senast den 12 februari för
              att undvika kostnadsränta. Ränta på belopp över 30 000 kronor
              börjar räknas från och med den 13 februari 2025.
            </ListItem>
            <ListItem>
              Senast den 2 maj ska din inkomstdeklaration ha inkommit till
              Skatteverket.
            </ListItem>
          </List>
        </ContentWrapper>
      ),
    },
    {
      title: 'Inkomstdeklaration 2024',
      details: (
        <ContentWrapper>
          <Paragraph>
            Önskar du hjälp med inkomstdeklarationen för 2024 kan vi bistå med
            upprättande och med olika typer av skattefrågor.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Frågor',
      details: (
        <ContentWrapper>
          <Paragraph>
            Om du har frågor eller vill diskutera något är du alltid välkommen
            att kontakta din förmögenhetsrådgivare.
          </Paragraph>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledInfoCard
        title="Information Deklarationsunderlag 2024"
        body="I slutet av januari kommer deklarationsunderlag för 2024 att finnas på kundportalen."
      />
      <GridElement columns={12}>
        <TitleWrapper>
          <Toggle
            label="Se alla"
            value={openAll}
            onChange={() => setOpenAll(!openAll)}
          />
        </TitleWrapper>
        {faqData.map((q, index) => (
          <CollapsibleListItem
            details={q.details}
            detailsStyle={q.detailsStyle}
            startExpanded={openAll}
            style={{ marginTop: SPACING_8, padding: SPACING_24 }}
            key={`faq-${camelcase(q.title)}-${index}`}
          >
            <H3 style={{ marginRight: 'auto' }}>{q.title}</H3>
          </CollapsibleListItem>
        ))}
      </GridElement>
    </>
  );
};
