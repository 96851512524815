import React from 'react';
import styled from 'styled-components';
import { groupBy } from 'lodash';

import { getAssetClassName, sortByAssetGroup } from '@formue-app/core';

import { SPACING_32, SPACING_24, SPACING_16 } from '../../constants/spacing';
import { GridElement, MainGrid } from '../layout';
import { H3 } from '../texts';
import { PreferenceGroup } from '../advisorsCorner/strategySimulator/PreferencesSettings';

const Container = styled(MainGrid)`
  width: 100%;
  margin: 0;
  margin-top: ${SPACING_32};
  padding: 0;
  max-width: 100%;
`;

export const InvestmentStrategyPreferences = (props) => {
  const {
    TitleComponent = H3,
    gap = SPACING_24,
    style,
    preferences,
    ...rest
  } = props;

  const preferencesByAssetGroup = groupBy(preferences, 'assetClassGroup');

  return (
    <Container style={{ gridGap: gap }} {...rest}>
      {Object.keys(preferencesByAssetGroup)
        .sort(sortByAssetGroup)
        .map((assetGroup) => {
          return (
            <GridElement columns={4} key={assetGroup}>
              <TitleComponent style={{ marginBottom: SPACING_16 }}>
                {getAssetClassName(assetGroup)}
              </TitleComponent>
              <PreferenceGroup
                preferencesByAssetGroup={preferencesByAssetGroup}
                assetGroup={assetGroup}
                style={style}
              />
            </GridElement>
          );
        })}
    </Container>
  );
};
