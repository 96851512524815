import React from 'react';
import styled, { css } from 'styled-components';

import {
  getAssetClassName,
  groupBy,
  percentage,
  sortByAssetGroup,
  t,
  useResource,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { HorizontalDivider } from '../../common/HorizontalDivider';
import { VisibleInCountry } from '../../common/VisibleInCountry';
import {
  H4,
  H5,
  Paragraph,
  ParagraphSmall,
  ParagraphXSmall,
} from '../../texts';

import {
  BORDER_RADIUS_EXTRA_LARGE,
  SPACING_16,
  SPACING_24,
  SPACING_4,
  SPACING_8,
} from '../../../constants/spacing';

import { ActivityIndicator } from '../../common/ActivityIndicator';
import { accent } from '../../../constants/colors';
import { Checkbox } from '../../formElements';

const StyledBaseSection = styled(BaseSection)`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${SPACING_8};
`;

const SliderGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};

  padding: ${SPACING_16};
  display: flex;
  flex-direction: column;
  border: 1px solid ${accent.sand1};
  border-radius: ${BORDER_RADIUS_EXTRA_LARGE};
`;

const StyledActivityIndicator = styled(ActivityIndicator)`
  margin: auto;
`;

const PreferenceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2;
  ${(props) =>
    props.core &&
    css`
      border-bottom: 1px solid ${accent.sand1};
      width: calc(100% + 32px);
      margin-left: -${SPACING_16};
      padding-inline: ${SPACING_16};
      padding-bottom: ${SPACING_16};
    `}
`;

const ProgressBarInner = styled.div`
  width: 90%;
  max-width: 90%;
  height: 8px;
  background-color: ${(props) => props.color}30;
  border-radius: 4px;
  position: relative;
`;

const ProgressBarValue = styled.div`
  height: 8px;
  border-radius: 4px;
  width: ${(props) => props.value * 100}%;
  background-color: ${(props) => props.color};
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
`;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
`;

const GroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_24};
`;

const PercentagePreference = ({
  preferenceType,
  name,
  label,
  value,
  color,
}) => {
  const core = preferenceType === 'core';

  return (
    <PreferenceWrapper core={core}>
      {core ? (
        <H5 style={{ marginBottom: SPACING_4 }}>
          {t(`advisor:strategySimulator:preferences:types:${name}`, {
            defaultValue: label,
          })}
        </H5>
      ) : (
        <ParagraphSmall>
          {t(`advisor:strategySimulator:preferences:types:${name}`, {
            defaultValue: label,
          })}
        </ParagraphSmall>
      )}
      <ProgressWrapper>
        <ProgressBarInner color={color}>
          <ProgressBarValue value={value} color={color}></ProgressBarValue>
        </ProgressBarInner>
        {core ? (
          <H5 style={{ minWidth: 40, textAlign: 'right' }}>
            {percentage(value * 100, false)}
          </H5>
        ) : (
          <ParagraphXSmall style={{ minWidth: 40, textAlign: 'right' }}>
            {percentage(value * 100, false)}
          </ParagraphXSmall>
        )}
      </ProgressWrapper>
    </PreferenceWrapper>
  );
};

const BooleanPreference = ({ preferenceType, label, name, value }) => {
  const core = preferenceType === 'core';

  return (
    <PreferenceWrapper>
      <CheckboxWrapper>
        {core ? (
          <H5 style={{ marginBottom: SPACING_4 }}>
            {t(`advisor:strategySimulator:preferences:types:${name}`, {
              defaultValue: label,
            })}
          </H5>
        ) : (
          <ParagraphSmall>
            {t(`advisor:strategySimulator:preferences:types:${name}`, {
              defaultValue: label,
            })}
          </ParagraphSmall>
        )}
        <Checkbox
          style={{ marginBottom: 0, marginLeft: 'auto' }}
          checked={value}
          disabled
        />
      </CheckboxWrapper>
    </PreferenceWrapper>
  );
};

const Preference = (props) => {
  const { valueType, color } = props;

  switch (valueType) {
    case 'percentage':
      return <PercentagePreference {...props} color={color} />;
    case 'boolean':
      return <BooleanPreference {...props} />;
    default:
      return null;
  }
};

export const PreferenceGroup = (props) => {
  const { preferencesByAssetGroup, assetGroup, style } = props;

  const colorLookup = {
    mv: accent.velvet1,
    s: accent.velvet2,
    av: accent.velvet3,
  };

  return (
    <SliderGroup style={style}>
      {preferencesByAssetGroup[assetGroup]
        .filter((item) => item.preferenceType === 'core')
        .sort((a, b) => a.order - b.order)
        .map((preference) => (
          <Preference {...preference} color={colorLookup[assetGroup]} />
        ))}
      {preferencesByAssetGroup[assetGroup]
        .filter((item) => item.preferenceType === 'satellite')
        .sort((a, b) => a.order - b.order)
        .map((preference) => (
          <Preference {...preference} color={colorLookup[assetGroup]} />
        ))}
    </SliderGroup>
  );
};

export const StrategyPreferencesSettings = (props) => {
  const { strategyId, strategy, preferences } = props;

  const loading = useResource([
    {
      type: 'INVESTMENT_STRATEGY_PORTFOLIO_CHARACTERISTICS/INDEX',
      args: { strategyId: strategyId },
    },

    {
      type: 'INVESTMENT_STRATEGY_PREFERENCES/INDEX',
      args: { strategyId: strategyId },
    },
  ]);

  const preferencesByAssetGroup = groupBy(preferences, 'assetClassGroup');

  return (
    <>
      <StyledBaseSection columns={4}>
        {loading ? (
          <StyledActivityIndicator />
        ) : preferences && preferences?.length ? (
          <GroupWrapper>
            {Object.keys(preferencesByAssetGroup)
              .sort(sortByAssetGroup)
              .map((assetGroup) => {
                return (
                  <div>
                    <H4 style={{ marginBottom: SPACING_8 }}>
                      {getAssetClassName(assetGroup)}
                    </H4>
                    <PreferenceGroup
                      preferencesByAssetGroup={preferencesByAssetGroup}
                      assetGroup={assetGroup}
                    />
                  </div>
                );
              })}
          </GroupWrapper>
        ) : strategy?.preferences ? (
          <>
            <SliderGroup>
              <H4>
                {t(
                  'advisor:strategySimulator:preferences:types:clientPreferences'
                )}
              </H4>
              <Row>
                <Paragraph>
                  {t('advisor:strategySimulator:preferences:indexExlution')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.index * 100, 0)}
                </Paragraph>
              </Row>
              <Row>
                <Paragraph>
                  {t(
                    'advisor:strategySimulator:preferences:sustainableInvestments'
                  )}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.sustainability, 0)}
                </Paragraph>
              </Row>
            </SliderGroup>

            <HorizontalDivider />

            <SliderGroup>
              <H4>
                {t(
                  'advisor:strategySimulator:preferences:types:spesificInvestmentPreferences'
                )}
              </H4>
              <Row>
                <Paragraph>
                  {t('advisor:strategySimulator:preferences:types:hedgeFund')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.mtaShare * 100, 0)}
                </Paragraph>
              </Row>
              <VisibleInCountry country={['no', 'dk']}>
                <Row>
                  <Paragraph>
                    {t(
                      'advisor:strategySimulator:preferences:types:realEstate'
                    )}
                  </Paragraph>
                  <Paragraph style={{ fontWeight: 500 }}>
                    {percentage(strategy?.preferences?.coreShare * 100, 0)}
                  </Paragraph>
                </Row>
              </VisibleInCountry>
              <Row>
                <Paragraph>
                  {t('advisor:strategySimulator:preferences:types:highYield')}
                </Paragraph>
                <Paragraph style={{ fontWeight: 500 }}>
                  {percentage(strategy?.preferences?.highYieldShare * 100, 0)}
                </Paragraph>
              </Row>
              <VisibleInCountry country={['no', 'dk']}>
                <Row>
                  <Paragraph>
                    {t(
                      'advisor:strategySimulator:preferences:types:curencyHedge'
                    )}
                  </Paragraph>
                  <Paragraph style={{ fontWeight: 500 }}>
                    {strategy?.preferences?.currencyHedging
                      ? t('globals:yes')
                      : t('globals:no')}
                  </Paragraph>
                </Row>
              </VisibleInCountry>
            </SliderGroup>
          </>
        ) : null}
      </StyledBaseSection>
    </>
  );
};
