import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { maxBy, minBy } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import {
  VictoryArea,
  VictoryChart,
  VictoryAxis,
  VictoryContainer,
} from 'victory';

import {
  formatBigMoney,
  formatHexOpacity,
  formatTwrTicks,
  toLocaleDateString,
  usePortfolioChartData,
  usePrevious,
} from '@formue-app/core';

import { accent } from '../../../../constants/colors';
import { bodyFontStack } from '../../../../fonts';
import { useBoundingRect } from '../../../../services/hooks/layout';
import { mobileCondition } from '../../../../constants/media';

const Container = styled.div`
  width: 100%;
  height: 400px;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
`;

export const PortfolioReturnChart = ({ dataExtractor = 'twr', loading }) => {
  const [localDataExtractor, setLocalDataExtractor] = useState(dataExtractor);
  const previousDataExtractor = usePrevious(dataExtractor);
  const [isVisible, setIsVisible] = useState(false);

  // We only want to zero pad data if dataextractor is TWR, for marketvalues it results
  // in wrong data.
  const chartData = usePortfolioChartData(dataExtractor === 'twr', true);

  const { ref, dimensions } = useBoundingRect();
  const isMobile = useMediaQuery({ query: mobileCondition });

  const colorSet =
    localDataExtractor === 'mv'
      ? [
          accent.velvet1,
          accent.velvet2,
          accent.velvet3,
          'rgba(109, 70, 148, 0.3)',
        ]
      : [accent.ocean1, accent.ocean2, accent.ocean3, 'rgba(70, 79, 129, 0.3)'];

  useEffect(() => {
    setIsVisible(false);
    if (previousDataExtractor) {
      if (previousDataExtractor !== dataExtractor) {
        // Since there is no "api loading pause" when switching between showing
        // "twr" and "market values" we want to artifically create one with this
        // timeout. We only do this to get a consistent animation of the chart.
        setTimeout(() => {
          setIsVisible(true);
          setLocalDataExtractor(dataExtractor);
        }, 350);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataExtractor]);

  useEffect(() => {
    if (!loading) {
      setIsVisible(true);
    } else {
      setIsVisible(true);
    }
  }, [loading]);

  let y0 = 0;

  if (chartData.length) {
    const maxValue = maxBy(chartData, localDataExtractor);
    const minValue = minBy(chartData, localDataExtractor);

    // If the minimum value isn't negative, we want to "push" the zero axis up a bit
    // We do this only to get a better visual experience
    y0 =
      minValue[localDataExtractor] >= 0
        ? 0 - maxValue[localDataExtractor] / 7
        : 0;
  }

  // We need different "transform correction" for mobile & desktop
  let transformXAxis = 'scale(0.97, 1), translate(6, 0)';
  if (isMobile) {
    transformXAxis = 'scale(0.93, 1), translate(5, 0)';
  }

  // We want to limit the number of XAxis ticks on mobile
  const MAXIMUM_DATAPOINTS_FOR_MOBILE = 62;
  const xTickMod =
    isMobile && chartData.length > MAXIMUM_DATAPOINTS_FOR_MOBILE ? 2 : 1;

  return (
    <Container ref={ref} isVisible={isVisible}>
      {chartData.length ? (
        <VictoryChart
          domainPadding={{ x: [0, 0], y: [0, 10] }}
          padding={{ bottom: 0, top: 20 }}
          height={dimensions.height / 3}
          width={dimensions.width / 3}
          scale={{ x: 'time', y: 'linear' }}
          containerComponent={
            <VictoryContainer
              style={{
                pointerEvents: 'auto',
                userSelect: 'auto',
                touchAction: 'auto',
              }}
            />
          }
        >
          <VictoryAxis
            crossAxis={false}
            dependentAxis
            offsetX={12}
            tickFormat={(tick) =>
              localDataExtractor === 'mv'
                ? formatBigMoney(tick)
                : formatTwrTicks(tick)
            }
            style={{
              axis: { stroke: 'transparent' },
              grid: { stroke: colorSet[3] },
              tickLabels: {
                fontSize: 5,
                fontWeight: 100,
                padding: 5,
                fill: colorSet[1],
                fontFamily: bodyFontStack,
                textAnchor: 'start',
              },
            }}
          />
          <VictoryAxis
            offsetY={16}
            scale="time"
            groupComponent={<g transform={transformXAxis} />}
            padding={{ right: 20, left: 10, bottom: 0 }}
            tickValues={chartData.map((value) => value.x)}
            tickFormat={(x, index) => {
              if (chartData.length < 15)
                return toLocaleDateString(x, {
                  month: 'short',
                });

              if (x.getMonth() === 0) {
                if (x.getFullYear() % xTickMod === 0) return x.getFullYear();
              }
            }}
            standalone={false}
            style={{
              axis: { stroke: 'transparent' },
              tickLabels: {
                fontSize: 5,
                fontWeight: 100,
                padding: 7,
                fill: colorSet[1],
                fontFamily: bodyFontStack,
                textAnchor: 'middle',
              },
            }}
          />
          <VictoryArea
            y={localDataExtractor}
            y0={(d) => y0}
            scale={{ x: 'time', twr: 'linear', mv: 'linear' }}
            standalone={false}
            data={chartData}
            interpolation="monotoneX"
            style={{
              data: {
                stroke: colorSet[2],
                strokeWidth: 1.5,
                fill: formatHexOpacity(colorSet[0], 0.2),
              },
            }}
          />
        </VictoryChart>
      ) : null}
    </Container>
  );
};
