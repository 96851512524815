import React, { useState } from 'react';
import styled from 'styled-components';
import camelcase from 'camelcase';

import { GridElement } from '../../../../components/layout';
import { CollapsibleListItem } from '../../../../components/common/CollapsibleListItem';
import { H2, H3, H4, Paragraph } from '../../../../components/texts';
import { TableList } from '../../../../components/lists';
import { List, ListItem } from '../../../../components/common/List';

import { Toggle } from '../../../../components/formElements';
import { desktopCondition, tabletCondition } from '../../../../constants/media';
import {
  SPACING_24,
  SPACING_16,
  SPACING_8,
} from '../../../../constants/spacing';
import { InfoCard } from '../../../../components/common/InfoCard';

const StyledInfoCard = styled(InfoCard)`
  margin-top: 10px;

  @media ${desktopCondition} {
    margin-right: 40px;
  }

  @media ${tabletCondition} {
    grid-column: span 12;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  max-width: 75%;
  padding: ${SPACING_24};
  padding-block: 0;
`;

const StyledList = styled(List)`
  margin: 0;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 55px;
  margin-bottom: ${SPACING_16};
`;

export const GeneralPage = (props) => {
  const year = 2024;
  const [openAll, setOpenAll] = useState(false);

  const faqData = [
    {
      title: 'Tidspunkter for publisering av rapporter og oppgaver',
      details: (
        <TableList
          sortable={false}
          columns={[
            {
              key: 'title',
              label: 'Rapport/Publiseringstidspunkt',
              render: ({ title, publishDate }) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <H4>{title}</H4>
                  <Paragraph>{publishDate}</Paragraph>
                </div>
              ),
            },
            {
              key: 'type',
              label: 'Type',
            },
            { key: 'source', label: 'Kilde/sted' },
          ]}
          rows={[
            {
              title: 'Innskudd, utlån og rente m.v. (Formueskonto)',
              publishDate: 'Slutten av Januar',
              type: 'Storebrand Nettbank',
              source: 'Storebrand Nettbank',
            },
            {
              title: 'Innskudd, utlån og rente m.v. 2024 (Kundekonto)',
              publishDate: 'Slutten av Januar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title: 'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA)',
              publishDate: 'Januar - mai',
              type: 'Formue Forretningsførsel',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'VPS-årsoppgave for VPS-registrerte verdipapirfond, AS og obligasjoner',
              publishDate: 'Februar',
              type: 'Verdipapirsentralen (VPS)',
              source: 'VPS',
            },
            {
              title:
                'Deltakeroppgaver fra deltakerliknende selskaper (IS, DA, KS)',
              publishDate: 'Februar - mai',
              type: 'Eksterne forretningsførere',
              source: 'Eksterne forretningsførere',
            },
            {
              title: 'Skatterapport Nominee 2024 (Årsoppgave)',
              publishDate: 'Ca midten av februar',
              type: 'Formue',
              source: 'Kundenett > Dokumenter',
            },
            {
              title:
                'Fradragsberettigede honorarer og skattepliktige inntekter',
              publishDate: 'Ca midten av mars',
              type: 'Formue',
              source: 'Kundenett > Skatt',
            },
          ]}
          keyExtractor={(item, index) => `faq-${index}`}
        />
      ),
    },
    {
      title: 'Hva er allerede fylt ut for meg i skattemeldingen?',
      details: (
        <ContentWrapper>
          <Paragraph>
            I skattemeldingen for 2024 er det viktig at du kontrollerer:
          </Paragraph>
          <StyledList>
            <ListItem>Alle forhåndsutfylte opplysninger </ListItem>
            <ListItem>Om noen opplysninger mangler</ListItem>
          </StyledList>
          <div>
            <Paragraph>
              Formue Norge AS rapporterer følgende opplysninger til
              skattemyndighetene:
            </Paragraph>
          </div>
          <StyledList>
            <ListItem>
              Aksjesparekonto, Verdipapirfond og Notes hos Formue finner du i
              dokumentet <b>Skatterapport Nominee 2024</b> i dokumentarkivet
            </ListItem>
            <ListItem>
              Oversikt over bankinnskudd og renter hos Formue finner du i
              dokumentet <b>Innskudd, utlån og rente m.v. 2024</b> i
              dokumentarkivet
            </ListItem>
          </StyledList>
          <Paragraph>
            Nytt av året er at fradragsberettigede honorarer og skattepliktige
            inntekter innrapporteres til skattemyndighetene og vil være
            forhåndsutfylt på skattemeldingen.
          </Paragraph>
          <Paragraph>
            Deltakeroppgaver for deltakere i Ansvarlige selskap (ANS), selskap
            med Delt Ansvar (DA), Indre Selskap (IS) og/eller Kommandittselskap
            (KS), publiseres en gang mellom januar og mai. Din andel av
            selskapets inntekt og formue som fremkommer på disse
            deltakeroppgavene, føres under tema “Formue og inntekt i selskap med
            deltakerfastsetting” i skattemeldingen. Dersom selskapet har levert
            sin selskapsmelding til skatteetaten før du leverer din
            skattemelding, vil andel av inntekt og formue komme opp som forslag.
            Vi anbefaler at du sjekker at beløpene stemmer med det som er
            oppgitt på din deltakeroppgave.
          </Paragraph>
          <Paragraph>
            Vi minner om at hvis du har private fordringer eller lån, f.eks.
            mellomværende med eget aksjeselskap, må du føre opp beløpet som
            skattepliktig formue eller fradragsberettiget gjeld i
            skattemeldingen din.
          </Paragraph>
          <div>
            <H3>Formueskonto </H3>
            <Paragraph>
              Formueskontoen rapporteres av Storebrand. Saldo- og renteoppgave
              finner du ved å logge deg inn på 
              <a
                href="https://nettbank.storebrand.no"
                target="_blank"
                rel="noreferrer"
              >
                https://nettbank.storebrand.no
              </a>
              .
            </Paragraph>
          </div>
          <div>
            <H3>VPS-konto</H3>
            <Paragraph>
              Hvis du har verdipapirkonto hos VPS finner du årsoppgave for
              VPS-kontoen ved å logge på VPS Investortjenester via 
              <a href="https://www.vps.no/" target="_blank" rel="noreferrer">
                https://www.vps.no
              </a>
              .
            </Paragraph>
          </div>
          <Paragraph>
            Pålogging kan skje med BankID, alternativt med brukernavn/passord
            fra VPS. Dersom du har glemt ditt påloggingspassord kan dette
            bestilles på samme side. Vi gjør oppmerksom på at det kun er
            privatpersoner som kan bestille nytt passord elektronisk og logge på
            med BankID. For bedriftskunder må tilsendt passord benyttes sammen
            med org.nr.
          </Paragraph>
          <Paragraph>
            For bestilling av nytt passord, send e-post til 
            <a href="mailto:kundeservice@formue.no">kundeservice@formue.no</a>.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Tilgang til kundenett for regnskapsførere eller andre',
      details: (
        <ContentWrapper>
          <Paragraph>
            Dersom du ønsker å gi tilgang til familiemedlemmer, regnskapsfører,
            revisor, ansatte eller styremedlemmer i selskap kan du kontakte din
            rådgiver eller sende oss en e-post{' '}
            <a href="mailto:kundeservice@formue.no">kundeservice@formue.no</a>.
          </Paragraph>
          <Paragraph>
            Vi må få oppgitt navn, fødsels- og personnummer, telefonnummer og
            adresse på den som skal ha lesetilgang. Vi vil deretter legge ut et
            skjema på kundenett/app som signaturberettiget må signere.
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title:
        'Hvor finner jeg informasjon om den skattemessige behandlingen av de ulike verdipapirene mine hos Formue?',
      details: (
        <ContentWrapper>
          <Paragraph>
            Under finner du informasjon om den skattemessige behandlingen av de
            ulike verdipapirene dine hos Formue.
          </Paragraph>
          <Paragraph>
            <a
              href="https://formue.my.salesforce.com/sfc/p/0Y000000pG6P/a/TY000000Z6Tx/oJJq2oBMLd60wUs9hquGW5XaOcXAFimaZnW16XGAGm0"
              target="_blank"
              rel="noreferrer"
            >
              Skattemessig behandling
            </a>
          </Paragraph>
        </ContentWrapper>
      ),
    },
    {
      title: 'Hvor finner jeg  formuesverdier  på aksjer for 2024?',
      details: (
        <ContentWrapper>
          <Paragraph>
            Alle aksjeselskap tilrettelagt av Formue Norge AS med
            forretningsførsel hos Formue Accounting AS rapporteres gjennom VPS
            og godkjente formuesverdier finnes i Årsoppgaven fra VPS for 2024.
            Dette må sammenholdes med opplysninger i aksjeoppgaven (RF-1088).
          </Paragraph>
          <Paragraph>
            Aksjeselskap og andre juridiske innretninger (ikke privatpersoner)
            som skal benytte verdi 31.12.2024, kan henvende seg til{' '}
            <a href="mailto:accounting@formue.no">accounting@formue.no</a> og få
            tilsendt verdien direkte.
          </Paragraph>
        </ContentWrapper>
      ),
    },
  ];

  return (
    <>
      <StyledInfoCard
        title="Fradragsberettigede honorarer og skattepliktige inntekter 2024"
        body="For å se dine fradragsberettigede honorarer og skattepliktige inntekter har vi laget en oversikt over dette på linken nedenfor."
        navLabel="Se oversikt"
        disabled={true}
        to={`/portfolio/reporting/tax/declaration/${year}`}
      />
      <GridElement columns={12}>
        <TitleWrapper>
          <H2>Ofte stilte spørsmål</H2>
          <Toggle
            label="Se alle"
            value={openAll}
            onChange={() => setOpenAll(!openAll)}
          />
        </TitleWrapper>
        {faqData.map((q, index) => (
          <CollapsibleListItem
            details={q.details}
            detailsStyle={q.detailsStyle}
            startExpanded={openAll}
            style={{ marginTop: SPACING_8, padding: SPACING_24 }}
            key={`faq-${camelcase(q.title)}-${index}`}
          >
            <H3 style={{ marginRight: 'auto' }}>{q.title}</H3>
          </CollapsibleListItem>
        ))}
      </GridElement>
    </>
  );
};
