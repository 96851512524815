import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import styled from 'styled-components';

import {
  selectors,
  t,
  strategy,
  transformActiveFilters,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { StrategyList } from '../strategy/PortfolioStrategyList';
import { Paragraph } from '../../texts';
import { printCondition } from '../../../constants/media';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    border-top: none;
  }
`;

const {
  ui: {
    portfolio: { activeStrategySelector, activeFiltersSelector },
  },
} = selectors;

export const PeriodicStrategy = () => {
  const activeStrategyId = useSelector(activeStrategySelector);
  const activeFilters = useSelector(activeFiltersSelector);

  const prevActiveFilters = useRef(null);

  let [activeStrategy, setActiveStrategy] = useState(null);
  const [strategyData, setStrategyData] = useState([]);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLocalLoading(true);
      let args = transformActiveFilters(activeFilters);
      args = {
        ...args,
        endDate:
          activeFilters.endDate || new Date().toISOString().split('T')[0],
      };
      const strategies = await strategy.get(args);

      const formatedData = strategies.data.map((item) => {
        return {
          id: item.id,
          type: item.type,
          ...item.attributes,
        };
      });

      setStrategyData(formatedData);
      setLocalLoading(false);
    };

    if (!isEqual(activeFilters, prevActiveFilters.current)) {
      prevActiveFilters.current = activeFilters;
      fetchData().catch(console.error);
    }
  }, [activeFilters]);

  useEffect(() => {
    setActiveStrategy(
      strategyData.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [strategyData, activeStrategyId]);

  return (
    <StyledBaseSection columns={4}>
      {localLoading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          {activeStrategy ? (
            <StrategyList strategy={activeStrategy.strategy} />
          ) : (
            <Paragraph>
              {t('portfolio:periodicReporting:strategy:noStrategyFound')}
            </Paragraph>
          )}
        </>
      )}
    </StyledBaseSection>
  );
};
