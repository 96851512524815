import moment from 'moment';

export const sortByDate = (format) => (a, b) => {
  return moment(b, format).format('X') - moment(a, format).format('X');
};

export const sortByAssetClass = (a, b) => {
  const order = ['b', 'o', 't', 'd', 'e', 'g', 'i', 'p'];
  return order.indexOf(a.assetClass) - order.indexOf(b.assetClass);
};

export const sortByAssetGroup = (a, b) => {
  const order = ['s', 'mv', 'av'];

  typeof a === 'object' ? (a = a.group) : a;
  typeof b === 'object' ? (b = b.group) : b;

  return order.indexOf(a?.toLowerCase()) - order.indexOf(b?.toLowerCase());
};

export const sortByFamilyhierarchy = (a, b) => {
  const order = ['mainmember', 'child', 'grandchild', 'other'];

  return order.indexOf(a) - order.indexOf(b);
};

export const sortMarketValueItems = (a, b) => {
  return a.p - b.p;
};

export const sortTransactionItems = (a, b) => {
  return (
    moment(b.dt, 'DD-MM-YYYY').format('X') -
    moment(a.dt, 'DD-MM-YYYY').format('X')
  );
};

export const sortByAmount = (a, b) => {
  return b.amount - a.amount;
};

export const sortStrategyType = (a, b) => {
  const order = [
    'OVERALL_STRATEGY',
    'FORMUE_PORTFOLIO',
    'ADVISORY_MANDATE',
    'DISCRETIONARY_MANAGEMENT',
    'PENSION_PORTFOLIO',
    'UNKNOWN',
  ];

  return order.indexOf(a.strategyType) - order.indexOf(b.strategyType);
};

export const sortByCostType = (a, b) => {
  const order = [
    'structuringFee',
    'advisoryFee',
    'oneTimeFee',
    'ongoingServiceFee',
    'productCost',
  ];
  return order.indexOf(a.costType) - order.indexOf(b.costType);
};
