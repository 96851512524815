import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { t, useResource, selectors } from '@formue-app/core';

import { InvestmentPhilosophy } from '../../components/investmentStrategies/InvestmentPhilosophy';
import { StrategyMarketValues } from '../../components/investmentStrategies/StrategyMarketValues';
import { StrategyAssetClasses } from '../../components/investmentStrategies/StrategyAssetClasses';
import { StrategyMetrics } from '../../components/investmentStrategies/StrategyMetrics';
import { H4 } from '../../components/texts';
import { accent, backgroundWhite } from '../../constants/colors';
import { StrategyProducts } from './StrategyProducts';
import { PortfolioCharacteristics } from './portfolioCharacteristics/PortfolioCharacteristics';
import { HistoricalPerformanceChart } from './HistoricalPerformanceChart';
import { HistoricalDrawdownChart } from './HistoricalDrawdownChart';
import { InvestmentStrategyImplementationPlan } from './InvestmentStrategyImplementationPlan';
import { InvestmentStrategyCostEstimate } from './InvestmentStrategyCostEstimate';
import { InvestmentStrategySection } from './InvestmentStrategySection';
import { InvestmentStrategyProducts } from './InvestmentStrategyProducts';
import { InvestmentStrategySummary } from './InvestmentStrategySummary';
import { StrategyComment } from './StrategyComment';
import { SPACING_16 } from '../../constants/spacing';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { InvestmentStrategyChildren } from './InvestmentStrategyChildren';
import { InvestmentStrategyPortfolioManagement } from './InvestmentStrategyPortfolioManagement';
import { InvestmentStrategyCostsMessage } from './InvestmentStrategyCostsMessage';
import { StrategyPreferences } from './StrategyPreferences';

const { operatingCountrySelector } = selectors.auth;

export const InvestmentStrategyContent = (props) => {
  const {
    id,
    investmentStrategy,
    advisoryFeeRelevant,
    structuringFeeRelevant,
    assetAllocationMetadata,
    investmentStrategyAssetAllocations,
    indexChangesMetadata,
    products,
    assetGroups,
    isParentStrategy,
    childStrategies,
    assetAllocations,
    costs,
    implementationPlan,
    termsAcceptedCallback,
    costsAcceptedCallback,
    eligibleForSigning,
  } = props;

  const loading = useResource([
    ...childStrategies.map((strategy) => ({
      type: 'INVESTMENT_STRATEGY_ASSET_ALLOCATIONS/INDEX',
      args: { strategyId: strategy.id },
    })),
  ]);

  const [implementationPlanType, setImplementationPlanType] =
    useState('overview');
  const [costEstimateType, setCostEstimateType] = useState('percentage');

  const operatingCountry = useSelector(operatingCountrySelector);

  if (isParentStrategy) {
    return (
      <>
        <StrategyMarketValues columns={12} strategy={investmentStrategy} />
        <StrategyAssetClasses
          columns={12}
          assetAllocations={investmentStrategyAssetAllocations}
          editableAssetAllocations={investmentStrategyAssetAllocations}
          assetGroups={assetGroups}
        />
        <StrategyMetrics
          strategyReturn={assetAllocationMetadata.expectedPerformance.return}
          strategyRisk={assetAllocationMetadata.expectedPerformance.risk}
          annualAverageReturn={
            indexChangesMetadata.historicalPerformance.annualAverageReturn
          }
          annualAverageRisk={
            indexChangesMetadata.historicalPerformance.annualAverageRisk
          }
        />
        {loading ? (
          <CenteredActivityIndicator />
        ) : (
          <InvestmentStrategyChildren
            childStrategies={childStrategies}
            assetAllocations={assetAllocations}
          />
        )}
      </>
    );
  }

  // Extract formue portfolio, return total portfolio if no external portfolio
  const formuePortfolio =
    investmentStrategy && investmentStrategy.externalPortfolio
      ? investmentStrategy.totalPortfolio - investmentStrategy.externalPortfolio
      : investmentStrategy?.totalPortfolio;

  return (
    <>
      <InvestmentStrategySection
        title={t('portfolio:strategy')}
        headerStyle={{ marginTop: isParentStrategy ? 0 : SPACING_16 }}
      >
        <InvestmentPhilosophy columns={12} />
        <StrategyMarketValues columns={12} strategy={investmentStrategy} />
        <StrategyAssetClasses
          columns={12}
          assetAllocations={investmentStrategyAssetAllocations}
          editableAssetAllocations={investmentStrategyAssetAllocations}
          assetGroups={assetGroups}
        />
        <StrategyMetrics
          strategyReturn={assetAllocationMetadata.expectedPerformance.return}
          strategyRisk={assetAllocationMetadata.expectedPerformance.risk}
          annualAverageReturn={
            indexChangesMetadata.historicalPerformance.annualAverageReturn
          }
          annualAverageRisk={
            indexChangesMetadata.historicalPerformance.annualAverageRisk
          }
        />
        <InvestmentStrategyProducts
          assetAllocations={investmentStrategyAssetAllocations}
          strategy={investmentStrategy}
          products={products}
        />

        <StrategyProducts
          totalPortfolio={investmentStrategy.totalPortfolio}
          formuePortfolio={formuePortfolio}
          strategyId={id}
          itemStyles={(assetClassKey, index) => ({
            backgroundColor: index % 2 === 1 ? backgroundWhite : accent.sand150,
            padding: '12px 16px',
          })}
          headingComponent={H4}
        />

        <StrategyPreferences
          strategyId={id}
          animate={true}
          gap={SPACING_16}
          columns={12}
        />

        {investmentStrategy.whyThisStrategyDescription ? (
          <StrategyComment
            title={t(
              'wealthPlan:investmentStrategy:whyThisStrategyDescription'
            )}
            body={investmentStrategy.whyThisStrategyDescription}
            columns={12}
            style={{ marginTop: 8 }}
          />
        ) : null}

        <HistoricalPerformanceChart strategyId={id} />
        <HistoricalDrawdownChart strategyId={id} />
      </InvestmentStrategySection>

      {
        // We don't want to show PortfolioCharacteristics if account type is "pension"
        investmentStrategy?.accountType === 'pension' ? null : (
          <InvestmentStrategySection
            title={t('portfolioConstruction:title')}
            grid={false}
          >
            <PortfolioCharacteristics
              strategyId={id}
              animate={true}
              gap={8}
              columns={12}
            />
            {investmentStrategy.clientsInvestmentSolutionDescription ? (
              <StrategyComment
                title={t(
                  'wealthPlan:investmentStrategy:clientsInvestmentSolutionDescription'
                )}
                body={investmentStrategy.clientsInvestmentSolutionDescription}
                columns={12}
                style={{ marginTop: 8 }}
              />
            ) : null}
          </InvestmentStrategySection>
        )
      }

      {implementationPlan.rounds && (
        <InvestmentStrategySection
          grid={false}
          title={t('advisor:strategySimulator:implementation:title')}
          options={[
            {
              label: t('portfolio:portfolioStrategy:overview'),
              value: 'overview',
            },
            {
              label: t('portfolio:portfolioStrategy:details'),
              value: 'details',
            },
          ]}
          onChange={(value) => setImplementationPlanType(value)}
        >
          <InvestmentStrategyImplementationPlan
            strategyId={id}
            columns={12}
            implementationPlan={implementationPlan}
            type={implementationPlanType}
          />
          {investmentStrategy.portfolioImplementationDescription ? (
            <StrategyComment
              title={t(
                'wealthPlan:investmentStrategy:portfolioImplementationDescription'
              )}
              body={investmentStrategy.portfolioImplementationDescription}
              columns={12}
              style={{ marginTop: 8 }}
            />
          ) : null}
        </InvestmentStrategySection>
      )}

      {investmentStrategy?.managementType === 'portfolioManagement' ? (
        <InvestmentStrategySection
          title={t('wealthPlan:investmentStrategy:portfolioManagement:title')}
          grid={false}
        >
          <InvestmentStrategyPortfolioManagement
            termsAcceptedCallback={termsAcceptedCallback}
            signingEnabled={eligibleForSigning}
          />
        </InvestmentStrategySection>
      ) : null}

      {costs.length ? (
        investmentStrategy.showCosts ? (
          <InvestmentStrategySection
            title={t('wealthPlan:investmentStrategy:costEstimate:title')}
            grid={false}
            options={[
              {
                label: t(
                  'presentation:annualReview:returnPerYear:returnInPercentage'
                ),
                value: 'percentage',
              },
              {
                label: t('presentation:annualReview:returnPerYear:returnInKr'),
                value: 'amount',
              },
            ]}
            onChange={(value) => setCostEstimateType(value)}
          >
            <InvestmentStrategyCostEstimate
              strategyId={id}
              investmentStrategy={investmentStrategy}
              advisoryFeeRelevant={advisoryFeeRelevant}
              structuringFeeRelevant={structuringFeeRelevant}
              costs={costs}
              columns={12}
              dataExtractor={costEstimateType}
            />
          </InvestmentStrategySection>
        ) : (
          <InvestmentStrategySection title={t('portfolio:cost')} grid={false}>
            <InvestmentStrategyCostsMessage
              costsAcceptedCallback={costsAcceptedCallback}
            />
          </InvestmentStrategySection>
        )
      ) : null}

      <InvestmentStrategySection
        title={t('wealthPlan:investmentStrategy:summary:title')}
        grid={false}
      >
        <InvestmentStrategySummary />
      </InvestmentStrategySection>
    </>
  );
};
