import { createSelector } from 'reselect';

import { getAssetGroupKey } from '../../../services/utilities/portfolio';
import { accessibleAccountsSelector } from '../user/selectors';
import { allFilterValuesSelector } from '../../entities/selectors/filterValues';
import {
  portfolioTotalSelector,
  portfolioAssetGroupsSelector,
} from '../../entities/selectors/portfolios';

const periodSelector = (state) => state.ui.portfolio.filters.period;
const externalReportingSelector = (state) =>
  state.ui.portfolio.filters.externalReporting;
const excludedSsidsSelector = (state) =>
  state.ui.portfolio.filters.excludedSsids;
const portfolioUiState = (state) => state.ui.portfolio;
const startAndEndDateSelector = createSelector(
  portfolioUiState,
  (portfolio) => {
    return {
      startDate: portfolio.filters.startDate,
      endDate: portfolio.filters.endDate,
    };
  }
);
export const activeStrategySelector = (state) =>
  state.ui.portfolio.activeStrategyId;

export const portfolioUpdatedSelector = createSelector(
  portfolioUiState,
  (portfolio) => {
    return {
      updatedAt: portfolio.updatedAt,
      calculatedAt: portfolio.calculatedAt,
    };
  }
);

/**
 * Merges the data from accessbile accounts and which accounts the
 * user as deselected in the portfolio filters and returns a list
 * of the accounts that we then determine is selected.
 */
const selectedRelationshipsSelector = createSelector(
  accessibleAccountsSelector,
  excludedSsidsSelector,
  (accounts, excludedSsids) => {
    if (!accounts || !excludedSsids) {
      return null;
    }
    return accounts
      .map((account) => account.ssid)
      .filter((ssid) => !excludedSsids.includes(ssid));
  }
);

/**
 * Returns a list of the selected investment accounts.
 * We don't want to include investment accounts for accounts that
 * we have excluded, so we need to filter the list the user has selected
 * in the UI to only remove values that belong to excluded accounts
 */
const getSelectedinvestmentAccountsSelector = createSelector(
  allFilterValuesSelector,
  (state) => state.ui.portfolio.filters.excludedInvestmentAccounts,
  selectedRelationshipsSelector,
  (allFilterValues, excludedInvestmentAccounts, selectedAccountIds) => {
    const investmentAccounts = allFilterValues.filter((item) =>
      item.hasOwnProperty('acc')
    );
    const selectedInvestmentAccounts = investmentAccounts.filter(
      (item) => !excludedInvestmentAccounts.includes(item.acc)
    );

    return selectedInvestmentAccounts
      .filter((item) => selectedAccountIds.includes(item.ssid.toString()))
      .map((item) => item.acc);
  }
);

/**
 * The same as for getSelectedinvestmentAccountsSelector goes here
 * it needs to be filter out values belonging to excluded accounts
 */

const getSelectedTaxClassesSelector = createSelector(
  allFilterValuesSelector,
  (state) => state.ui.portfolio.filters.excludedTaxClasses,
  selectedRelationshipsSelector,
  (allFilterValues, excludedTaxClasses, selectedAccountIds) => {
    const taxClasses = allFilterValues.filter((item) =>
      item.hasOwnProperty('tax')
    );
    const selectedTaxClasses = taxClasses.filter(
      (item) => !excludedTaxClasses.includes(item.tax)
    );

    return selectedTaxClasses
      .filter((item) => selectedAccountIds.includes(item.ssid.toString()))
      .map((item) => item.tax);
  }
);

export const activeFiltersSelector = createSelector(
  selectedRelationshipsSelector,
  periodSelector,
  startAndEndDateSelector,
  getSelectedinvestmentAccountsSelector,
  getSelectedTaxClassesSelector,
  externalReportingSelector,
  (
    ssids,
    period,
    filterDates,
    investmentAccounts,
    taxClasses,
    externalReporting
  ) => {
    // We need relationships before we can create filters
    if (!ssids) {
      return null;
    }

    return {
      ssids,
      period,
      startDate: filterDates.startDate,
      endDate: filterDates.endDate,
      account: investmentAccounts,
      taxClass: taxClasses,
      showInternalPortfolio: externalReporting.includes(
        'showInternalPortfolio'
      ),
      showExternalPortfolio: externalReporting.includes(
        'showExternalPortfolio'
      ),
    };
  }
);

export const assetGroupDataSelector = createSelector(
  portfolioTotalSelector,
  portfolioAssetGroupsSelector,
  (total, assetGroups) => {
    if (!total || !assetGroups) {
      return null;
    }

    const { mv: totalMarketValue } = total;
    const data = assetGroups.reduce((map, assetGroup) => {
      const { group, mv: marketValue } = assetGroup;
      const name = getAssetGroupKey(group);
      const share = (marketValue / totalMarketValue) * 100;

      map[name] = {
        share,
        marketValue,
        group,
      };

      return map;
    }, {});

    return data;
  }
);
