import { useDispatch, useSelector } from 'react-redux';

import { selectors, actions } from '../../../store';
import { operatingCountrySelector } from '../../../store/auth/selectors';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

const { taxClassesSelector } = selectors.entities.filterValues;

export const usePortfolioTaxClassFilter = () => {
  const dispatch = useDispatch();
  const operatingCountry = useSelector(operatingCountrySelector);
  // Make sure list is unique
  const taxClasses = [...new Set(useSelector(taxClassesSelector))];
  const excludedTaxClasses = useSelector(
    (state) => state.ui.portfolio.filters.excludedTaxClasses
  );

  // Tax classes are not yet ready for Norway, but is awailable for other countries
  if (!taxClasses.length || operatingCountry === 'no') return [];

  const canChange = (taxClass) => {
    const allButOneExcluded =
      excludedTaxClasses.length + 1 === taxClasses.length;
    if (!allButOneExcluded) {
      return true;
    }

    // if account is in excluded, it can be toggled, if not that would
    // cause a problem since we have then excluded _all_ ssids, so that
    // should not be allowed
    if (excludedTaxClasses.includes(taxClass)) {
      return true;
    }

    return false;
  };

  const options = taxClasses.map((taxClass) => ({
    name: taxClass,
    disabled: !canChange(taxClass),
    selected: !excludedTaxClasses.includes(taxClass),
    onClick: () => dispatch(uiPortfolioActions.filterTaxClass(taxClass)),
  }));

  return options;
};
