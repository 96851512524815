import { stringify } from 'query-string';
import { apiFetch } from './utils/api';

// const mockedStrategy = require('./mocks/v3/strategy.json');

export const strategy = {
  get: async (args) => {
    const query = stringify(args, { arrayFormat: 'comma' });

    const options = {
      method: 'GET',
    };

    const url = `v3/strategy?${query}`;

    const { body } = await apiFetch(url, {
      ...options,
    });
    // return mockedStrategy;
    return body;
  },
};
