import { WelcomeSlide } from './WelcomeSlide';
import { CostSlide } from './CostSlide';
import { CostDetails } from './CostDetails';
import { RiskProfileSlide } from './RiskProfileSlide';
import { RiskProfileDetails } from './RiskProfileDetails';
import { ExpectedRiskAndReturnSlide } from './ExpectedRiskAndReturnSlide';
import { HistoricalPerformanceSlide } from './HistoricalPerformanceSlide';
import { HistoricalDrawdownSlide } from './HistoricalDrawdownSlide';
import { ForecastSlide } from './ForecastSlide';
import { StrategyAlternativeSlide } from './StrategyAlternativeSlide';
import { MatrixSlide } from './MatrixSlide';
import { ConstructionSlide } from './ConstructionSlide';
import { ChooseStrategySlide } from './ChooseStrategySlide';
import { ChooseStrategyDetails } from './ChooseStrategyDetails';
import { ThankYouSlide } from './ThankYouSlide';
import { GeographySlide } from './GeographySlide';
import { SectorsSlide } from './SectorsSlide';
import { SustainabilitySlide } from './SustainabilitySlide';
import { PortfolioConstructionSlide } from './PortfolioConstructionSlide';
import { ImplementationPlanSlide } from './ImplementationPlanSlide';
import { HistoricalRiskAndReturnSlide } from './HistoricalRiskAndReturnSlide';
import { PortfolioCharacteristicsSlide } from './PortfolioCharacteristicsSlide';
import { StrategyAlternativeDetails } from './StrategyAlternativeDetails';

export const investmentStrategySlides = {
  WelcomeSlide,
  CostSlide,
  CostDetails,
  RiskProfileSlide,
  RiskProfileDetails,
  ExpectedRiskAndReturnSlide,
  HistoricalPerformanceSlide,
  HistoricalDrawdownSlide,
  ForecastSlide,
  StrategyAlternativeSlide,
  StrategyAlternativeDetails,
  MatrixSlide,
  ConstructionSlide,
  ChooseStrategySlide,
  ChooseStrategyDetails,
  ThankYouSlide,
  GeographySlide,
  SectorsSlide,
  SustainabilitySlide,
  PortfolioConstructionSlide,
  ImplementationPlanSlide,
  HistoricalRiskAndReturnSlide,
  PortfolioCharacteristicsSlide,
};
