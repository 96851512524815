import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectors, t, useResource } from '@formue-app/core';

import { accent, backgroundMain, textLightest } from '../../constants/colors';
import { SPACING_32 } from '../../constants/spacing';

import { BaseSection } from '../../components/common/BaseSection';
import { GridElement } from '../../components/layout/GridElement';
import { CenteredActivityIndicator } from '../../components/common/ActivityIndicator';
import { HeaderWithBackgroundImage } from '../../components/common/HeaderWithBackgroundImage';
import { NoDataMessage } from '../../components/wealthPlan/NoDataMessage';
import { PortfolioStrategyContent } from '../../components/portfolio/strategy/PortfolioStrategyContent';
import { StrategyPicker } from '../../components/portfolio/strategy/StrategyPicker';
import { PortfolioConstructionPyramid } from '../../components/portfolio/strategy/PortfolioConstructionPyramid';

import backgroundImage from '../../assets/portfolio/strategy-background.jpg';

const StyledContainer = styled(BaseSection)`
  background-color: transparent;
  padding: 0;
`;

const StyledCenteredActivityIndicator = styled(CenteredActivityIndicator)`
  margin-top: 50px;
`;

const StyledStrategyPicker = styled(StrategyPicker)`
  margin-bottom: ${SPACING_32};
`;

const {
  entities: {
    strategy: { strategyHasContent },
  },
} = selectors;

export const PortfolioStrategyPage = () => {
  const loading = useResource(['STRATEGIES/INDEX']);
  const hasContent = useSelector(strategyHasContent);

  if (loading) {
    return (
      <GridElement columns={12}>
        <StyledCenteredActivityIndicator
          columns={12}
          size={30}
          background={backgroundMain}
        />
      </GridElement>
    );
  }

  return (
    <>
      <StyledContainer columns={12}>
        <HeaderWithBackgroundImage
          title={t('portfolio:portfolioStrategy:title')}
          subtitle={t('portfolio:portfolioStrategy:intro')}
          backgroundImage={backgroundImage}
          textColor={textLightest}
          subtitleColor={accent.velvet2}
          sideChildren={true}
        >
          <StyledStrategyPicker filterStrategies={false} />
          <PortfolioConstructionPyramid
            enableClick={false}
            height={222}
            labelPosition="right"
          />
        </HeaderWithBackgroundImage>
        {hasContent ? (
          <PortfolioStrategyContent />
        ) : (
          <NoDataMessage text={t('portfolio:portfolioStrategy:noData')} />
        )}
      </StyledContainer>
    </>
  );
};
