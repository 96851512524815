import { useDispatch, useSelector } from 'react-redux';

import { t } from '../../../services/localization';
import { selectors, actions } from '../../../store';

const {
  ui: { portfolio: uiPortfolioActions },
} = actions;

const { investmentAccountsSelector } = selectors.entities.filterValues;

export const usePortfolioInvestmentAccountsFilter = () => {
  const dispatch = useDispatch();
  const investmentAccounts = useSelector(investmentAccountsSelector);

  // We want to exclude closed investment accounts so filter out closed ones
  const activeInvestmentAccounts = investmentAccounts.filter(
    (a) => a.status !== 'closed'
  );
  const closedInvestmentAccounts = investmentAccounts.filter(
    (a) => a.status === 'closed'
  );

  // Make sure list is unique
  const uniqueInvestmentAccountsNames = [
    ...new Set(investmentAccounts.map((a) => a.name)),
  ];
  const uniqueActiveInvestmentAccountNames = [
    ...new Set(activeInvestmentAccounts.map((a) => a.name)),
  ];

  const excludedInvestmentAccounts = useSelector(
    (state) => state.ui.portfolio.filters.excludedInvestmentAccounts
  );

  if (!uniqueInvestmentAccountsNames.length) return [];

  // Helper function to determine if a toggle switch can be disabled or not.
  // if all except one is excluded already, one should not be allowed to disable
  // the last one before another is enabled. The data source requires at least
  // one account to make any sense.
  const canChange = (account) => {
    const allButOneExcluded =
      excludedInvestmentAccounts.length + 1 >=
      uniqueActiveInvestmentAccountNames.length +
        (closedInvestmentAccounts.length ? 1 : 0);

    if (!allButOneExcluded) {
      return true;
    }

    // if account is in excluded, it can be toggled, if not that would
    // cause a problem since we have then excluded _all_ ssids, so that
    // should not be allowed
    if (excludedInvestmentAccounts.includes(account)) {
      return true;
    }

    return false;
  };

  const options = uniqueActiveInvestmentAccountNames.map(
    (investmentAccount) => ({
      name: investmentAccount,
      disabled: !canChange(investmentAccount),
      selected: !excludedInvestmentAccounts.includes(investmentAccount),
      onClick: () =>
        dispatch(uiPortfolioActions.filterInvestmentAccount(investmentAccount)),
    })
  );

  // if we have any closed investment accounts we append one option to the list
  // that we can handle differently
  const closedInvestmentAccountsNames = [
    ...new Set(closedInvestmentAccounts.map((a) => a.name)),
  ];

  options.push({
    name: t('filters:closedInvestmentAccounts'),
    disabled: !canChange(closedInvestmentAccountsNames[0]), // if one of the closed ones are disabled, all are
    selected: !excludedInvestmentAccounts.includes(
      closedInvestmentAccountsNames[0]
    ),
    status: 'closed',
    onClick: () =>
      dispatch(
        uiPortfolioActions.filterInvestmentAccount(
          closedInvestmentAccountsNames
        )
      ),
  });

  return options;
};
